import { LoanTypeEnum } from "../models/enums/LoanTypeEnum";
import { QuestionIdEnum } from "../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../models/enums/QuestionIdToRoute";

/**
 * Helper class for questions
 */
export class QuestionHelper {

    /**
     * Help decide what question we should go to based on the loan type
     * Note this does not handle navigation it just get the question we are trying to go to
     */
    static GetPurchaseOrRefiNextQuestion = (product:LoanTypeEnum, purchaseQuestion:QuestionIdEnum, 
        refinanceQuestion:QuestionIdEnum) => {
            //If the loan type is purchase
            if(product === LoanTypeEnum.Purchase) {
                return QuestionIdToRoute[purchaseQuestion];
            }

            //If the loan type is refinance
            return QuestionIdToRoute[refinanceQuestion]

        
    }
}