import React, {useState} from 'react';
import { BoolHelper } from '../helpers/BoolHelper';
import { EnumHelper } from '../helpers/EnumHelper';
import { StringHelper } from '../helpers/StringHelper';
import { basicInfoFields } from '../models/formFields/BasicInformationFields';

/**
 * Custom hook for handling and setting form data
 * @param defaultState 
 * @returns 
 */
const useFields = (defaultState:any) => {
    // Creating a state for form data
    const [formData, setFormData] = useState(defaultState);

    // Handles the on change event for input form data
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, validateForEnum?:boolean, enumObj?:any) => {
        const name = e.target.name;

        let value:string | number = e.target.value;   
        
        if(name === basicInfoFields.BasicInfoZip)
            value = StringHelper.removeNonDigits(value);

        if(validateForEnum && enumObj) {
            value = parseInt(value);

            if(EnumHelper.checkIfWithinEnum(value, enumObj))
                throw new Error("Invalid Input");
        }

        setFormData((fData:any) => ({
            ...fData,
            [name]: value
        }));
    };

    /**
     * Handles on changes for only setting booleans,
     * so a property is not created and formData will just hold the value
     * @param e 
     * @returns 
     */
    const handleBoolChange = (e: React.ChangeEvent<HTMLInputElement>) =>  {
        let value:string | boolean = e.currentTarget.value;

        value = BoolHelper.convertStringToBool(value);

        setFormData(value);        
    }

    /**
     * Handles input and validate that it is within the enum's range
     * @param e 
     * @param enumObj 
     */
    const handleEnumChange = (e: React.ChangeEvent<HTMLInputElement>, enumObj:any) => {        
        let value:string | number = e.target.value;    
            
        value = parseInt(value);

        if(!EnumHelper.checkIfWithinEnum(value, enumObj))
            throw new Error("Invalid Input");        

        setFormData(value);
    }

    // resets the form data to a default state
    const resetFormData = () => {
        setFormData(defaultState);
    };

    return [formData, handleChange, handleBoolChange, handleEnumChange, resetFormData, setFormData]
}

export default useFields