import { string } from "zod"
import { LoanTypeEnum } from "../enums/LoanTypeEnum"
import { analyticsType, baseAnalyticsType } from "./analytics/analyticsType"
import { BasicInfo } from "./basicInfo/BasicInfo"
import { baseBorrowerType } from "./borrower/BorrowerType"
import { currentLoanType } from "./currentLoan/CurrentLoanType"
import LoanLead from "./LoanLead"
import { newLoanType } from "./newLoan/NewLoanType"
import { propertyType } from "./property/PropertyType"
import { ThirdPartyRequiredFields } from "./thirdPartyRequiredFields/ThirdPartyRequiredFields"

export type LeadStorageType = {
    id:string
    analytics:baseAnalyticsType
    borrower:baseBorrowerType
    currentLoan:currentLoanType
    newLoan:newLoanType
    percentDown:number
    property:propertyType
    propertyValue:number
    product:LoanTypeEnum
    thirdParty:ThirdPartyRequiredFields
}

/**
 * Class for storing lead question answers in session or local storage
 */
export class LeadStorage implements LeadStorageType {
    id: string
    
    analytics: baseAnalyticsType
    
    borrower: baseBorrowerType
    
    currentLoan: currentLoanType
    
    newLoan: newLoanType

    product: LoanTypeEnum
    
    percentDown: number
    
    property: propertyType
    
    propertyValue: number

    basicInfo: BasicInfo

    thirdParty: ThirdPartyRequiredFields


    constructor(
        id:string,     
        analytics:baseAnalyticsType,
        borrower:baseBorrowerType,
        currentLoan:currentLoanType,
        newLoan:newLoanType,
        percentDown:number,
        property:propertyType,
        propertyValue:number,
        product:LoanTypeEnum,
        basicInfo:BasicInfo,
        thirdParty:ThirdPartyRequiredFields,
    ) {
        this.id = id;
        this.analytics = analytics;
        this.borrower = borrower;
        this.currentLoan = currentLoan;
        this.newLoan = newLoan;
        this.percentDown = percentDown;
        this.property = property;
        this.product = product;
        this.propertyValue = propertyValue;
        this.basicInfo = basicInfo;
        this.thirdParty = thirdParty;
    }

    /**
     * Prepares the lead to be stored in storage by converting it to
     * lead storage
     * @param loanLead 
     * @returns 
     */
    static convertLeadToStorage = (loanLead:LoanLead) => {
        const id = loanLead.id;
        const {workflow, clickedCreditScore, completedForm, LastPageVisited, cid, ppcid} = loanLead.analytics;
        const {militaryService, bankruptcyOrForeclose, lateMortgageAmount, creditScore, borrowCash} = loanLead.borrower;    
        const currentLoan = loanLead.currentLoan;
        const newLoan = loanLead.newLoan;
        const percentDown = loanLead.percentDown;
        const property = loanLead.property;
        const propertyValue = loanLead.propertyValue;
        const product = loanLead.product;
        const basicInfo = loanLead.borrower.basicInfo;
        const thirdParty = loanLead.thirdPartyRequirements;
        
        const analytics:baseAnalyticsType = {
            workflow,
            clickedCreditScore,
            completedForm,
            LastPageVisited,
            cid:cid,
            ppcid
        }

        const borrow:baseBorrowerType = {
            militaryService,
            bankruptcyOrForeclose,
            lateMortgageAmount,
            creditScore,
            borrowCash,
        }
        
        return new LeadStorage(
            id, 
            analytics, 
            borrow, 
            currentLoan, 
            newLoan, 
            percentDown, 
            property, 
            propertyValue, 
            product, 
            basicInfo, 
            thirdParty
        );
    }

    /**
     * Converts a leadStorage to a new loan lead
     * @param lead 
     * @returns 
     */
    static convertStorageToLead = (lead:LeadStorage) => {
        const loanLead = new LoanLead();
        
        loanLead.id = lead.id;
        loanLead.currentLoan = lead.currentLoan;
        loanLead.newLoan = lead.newLoan;
        loanLead.percentDown = lead.percentDown;
        loanLead.product = lead.product;
        loanLead.property = lead.property;
        loanLead.propertyValue = lead.propertyValue;

        // we are spreading properties that are not in the leadStorage 
        loanLead.analytics = {
            ...loanLead.analytics,
            ...lead.analytics
        };
        loanLead.borrower = {
            ...loanLead.borrower,
            ...lead.borrower
        };

        loanLead.borrower.basicInfo = {
            ...loanLead.borrower.basicInfo,
            ...lead.basicInfo
        }

        loanLead.thirdPartyRequirements = {
            ...loanLead.thirdPartyRequirements,
            ...lead.thirdParty
        }

        return loanLead;
    }
}