import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import LoanLead from '../models/LoanLeads/LoanLead';
import { StringHelper } from '../helpers/StringHelper';
import { LoanLeadRequest } from '../models/requests/LoanLeadRequest';
import { fieldToValueType, PatchJSONDocument } from '../models/requests/PatchJSONDocument';
import { googleReCAPTCHARequest } from '../models/googleReCAPTCHA/GoogleReCAPTCHARequest';

/**
 * Services for accessing the The Texcorp FHA Form Azure function
 * As of this time there is only one Azure function in use and that is InsertLead
 */
export class TexcorpService {
    private baseUrl = process.env.REACT_APP_BASE_URL
    private axiosConfiguration:AxiosRequestConfig = {
        // withCredentials: true,
        headers: {
            'Content-Type': 'application/json',            
            'x-functions-key': process.env.REACT_APP_X_FUNCTION_KEY ? process.env.REACT_APP_X_FUNCTION_KEY : "",
            'x-timestamp': new Date().toUTCString()
        }
    }    

    /**
     * Posts a lead to the given endpoint
     * @param lead 
     * @param endPoint 
     */
    private postLead = async (lead:LoanLead, endPoint:string, signal?:AbortSignal) => {
        if(StringHelper.isNullOrWhiteSpace(this.baseUrl)) {
            throw new Error("baseUrl is empty try checking env or config variables");
        }
        
        const leadRequest = LoanLeadRequest.convertLeadToRequest(lead);

        if(this.axiosConfiguration.headers){
            //Per each request we need to get a new timestamp or we will be considered unauthorized (backend insertLead azure func was not made by me)
            this.axiosConfiguration.headers['x-timestamp'] = new Date().toUTCString();
        }

        if(signal)
            this.axiosConfiguration.signal = signal;

        const resp:AxiosResponse = await axios.post(this.baseUrl + endPoint as string, leadRequest, this.axiosConfiguration);

        //TODO check to see if response was successful,etc
        return resp;
    }

    /**
     * Inserts the lead to the Texcorp DB
     * @param lead 
     * @returns 
     */
    public insertLead = async (lead:LoanLead) => {
        const endPoint = "InsertLead";
        
        return this.postLead(lead, endPoint);
    }

    /**
     * Update lead fields without overwriting the lead
     * @param lead 
     * @param fieldToValues 
     * @returns 
     */
    public updateLeadFields = async (lead:LoanLead, fieldToValues:fieldToValueType[]) => {
        const endPoint = "PatchLead?leadId=" + lead.id;

        if(this.axiosConfiguration.headers){            
            this.axiosConfiguration.headers['x-timestamp'] = new Date().toUTCString();
        }

        const patchDocuments:PatchJSONDocument[] = fieldToValues.map((fieldToValue) => {
            return new PatchJSONDocument(fieldToValue.field, fieldToValue.value);
        });

        const resp:AxiosResponse = await axios.patch(this.baseUrl + endPoint, patchDocuments, this.axiosConfiguration);

        return resp;
    }

    /**
     * Submits a lead to one of the two vendors, Peklava or Lead Point
     * @param lead 
     * @returns 
     */
    public submitToLeadBuyer = async (lead:LoanLead) => {
        const endPoint = "SubmitToLeadVendor";

        return this.postLead(lead, endPoint);
    }   

    /**
     * Services that sends a request to our api to send a request to verify our recaptcha from google
     * Google once you to verify recaptchas' on a backend and not on the frontend
     * @returns 
     */
    public verifyGoogleRecaptcha = async () => {
        const endPoint = "VerifyGoogleRecaptcha";

        if(this.axiosConfiguration.headers)
            this.axiosConfiguration.headers['x-timestamp'] = new Date().toISOString();
                    
        //@ts-ignore
        if(!window.grecaptcha)
            return null;
            
        const googleRequest:googleReCAPTCHARequest = {
            //@ts-ignore
            recaptchaClientResponse: window.grecaptcha.getResponse()
        }

        let resp:AxiosResponse | null = null;

        try {
            resp = await axios.post(this.baseUrl + endPoint, googleRequest);
            
        } catch (error) {
            return error as Error;     
        }

        return resp;
    }
}