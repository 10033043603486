const TexcorpStatement = () => {
    return (
        <div className="texcorp-privacy-statement">
            <p>
                We take your privacy seriously. Your phone number, e-mail address and other information is secure. 
                Any data submitted to this website uses a Comodo SSL certificate 
                to protect online transactions with the highest strength encryption.
            </p>
        </div>
    )
}

export default TexcorpStatement