import { useEffect } from "react";
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useFields from "../../../hooks/UseFields";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { LoanTypeEnum } from "../../../models/enums/LoanTypeEnum";
import { PropertyTypeEnum } from "../../../models/enums/PropertyTypeEnum";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

const prevNextQuestionObj = {
    purchase: {
        prev: QuestionIdToRoute[QuestionIdEnum.MinDownPayment],
        next: QuestionIdToRoute[QuestionIdEnum.FoundHome],
    },
    refinance: {
        prev: QuestionIdToRoute[QuestionIdEnum.BorrowCash],
        next: QuestionIdToRoute[QuestionIdEnum.MilitaryService],
    }
}

const PropertyDescription = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.PropertyDescription;        
    
    const [formData, , ,handleEnumChange] = useFields(loanLead.property.propertyDescription);

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    useEffect(() => {
        function onFormDataChange() {
            const lead:LoanLead = DeepCopy.copy(loanLead);

            lead.property.propertyDescription = formData;

            updateAndStoreLead(lead);
        }
        onFormDataChange();
    }, [formData]);

    /**
     * Get the prev or next question route/path 
     * based on the current lead's loan type
     */
    const getQuestionPaths = () => {
        const {product} = loanLead;

       return  product === LoanTypeEnum.Purchase ? prevNextQuestionObj.purchase : prevNextQuestionObj.refinance
    }

    const previousQuestion = getQuestionPaths().prev;
    
    const nextQuestion = getQuestionPaths().next;
        
    return (
        <>
            <div className="question-header-container">
                <h3 className="question-header">Property Description</h3>
            </div>

            <div className="form-element">
                <div className="btn-container-3">
                    <div className="custom-radio-container">
                        <input 
                            checked={formData === PropertyTypeEnum.Single} 
                            type="radio" 
                            value={PropertyTypeEnum.Single} 
                            id="singleFamily" 
                            name="propertyDescription" 
                            onChange={(e) => handleEnumChange(e, PropertyTypeEnum)} 
                        />
                        <label className="btn-single btn-three-question" htmlFor="singleFamily" aria-label="Single Family"></label>
                    </div>

                    <div className="custom-radio-container">
                        <input 
                            checked={formData === PropertyTypeEnum.Condo}
                            type="radio" 
                            value={PropertyTypeEnum.Condo}
                            id="condominium" 
                            name="propertyDescription"
                            onChange={(e) => handleEnumChange(e, PropertyTypeEnum)} 
                        />
                        <label className="btn-condo btn-three-question" htmlFor="condominium" aria-label="Condominium"></label>
                    </div>

                    <div className="custom-radio-container">
                        <input 
                            checked={formData === PropertyTypeEnum.Multi}
                            type="radio" 
                            value={PropertyTypeEnum.Multi} 
                            id="multiFamily" 
                            name="propertyDescription" 
                            onChange={(e) => handleEnumChange(e, PropertyTypeEnum)} 
                        />
                        <label className="btn-multi-fam btn-three-question" htmlFor="multiFamily" aria-label="Multi Family"></label>
                    </div>
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
} 

export default PropertyDescription