import { CreditScoreEnum } from "../../enums/CreditScoreEnum";
import { LateMortgageEnum } from "../../enums/LateMortgageAmountEnum";
import { BasicInfo, defaultBasicInfo } from "../basicInfo/BasicInfo";

export type baseBorrowerType = {
    /**
     * if the lead or spouse served in the military
     */
     militaryService:boolean
    
     /**
      * Whether or not the lead went bankrupt or have foreclosed
      */
     bankruptcyOrForeclose:boolean
     
     /**
      * the amount of late mortgage payments a lead has
      */
     lateMortgageAmount:LateMortgageEnum
     
     /**
      * The credit score of the lead
      */
     creditScore:CreditScoreEnum
 
     /**
      * Does the lead want to borrow additional cash or not
      */
     borrowCash:boolean
}

export type borrowerType = {

    /**
     * Lead's basic info grouped together
     */
    basicInfo:BasicInfo
} & baseBorrowerType

export const defaultBorrowerFields:borrowerType = {
    basicInfo: defaultBasicInfo,
    militaryService: false,
    bankruptcyOrForeclose: false,
    lateMortgageAmount: LateMortgageEnum.none,
    creditScore: CreditScoreEnum.Good,
    borrowCash:false
}