const PeklavaStatement = () => {
    return (
        <div>                
            <p>
                <input type="hidden" id="leadid_tcpa_disclosure_2" />
                <label htmlFor="leadid_tcpa_disclosure_2">
                    By Clicking on the above button, I hereby consent, acknowledge and agree to the 
                    {" "}<a href="https://www.ratezip.com/privacy-and-security/" 
                    target="_blank" rel="noreferrer">privacy policy</a> {" "}
                    and agree to receive notices and other communication electronically. I consent to receive text messages and
                    auto-dialed and/or pre-recorded telemarketing calls, texts, voicemails (which may use artificial voices and may be recorded), and
                    email from up to five (5) licensed mortgage lenders and insurance providers participating with RateZip,
                    parties calling on behalf of RateZip and its {" "}<a href="https://www.ratezip.com/network-members/" 
                    target="_blank" rel="noreferrer">participating providers</a> {" "}
                    and from other authorized third parties and their networks of service providers, regarding my inquiry and other home-related matters, and that they may contact me at the telephone number provided here, including my
                    wireless number, if applicable, even if that telephone number is on a corporate, state, or national Do Not Call Registry.
                    I understand that I can elect to not receive such calls or messages at any time, and that consent is not a condition of purchase. I understand that msg/data rates may apply.
                    You agree that we can share your personal data with third parties, such as our mortgage partners, Insurance partners, service providers
                    and other affiliates, and that we can use this data for marketing and analytics, and to make your experience easier.
                    For SMS message campaigns: Text STOP to (866)362-7999 to stop and HELP for help. Msg &amp; data or carrier rates may apply.
                    I confirm I have received and reviewed the Mortgage Broker Disclosures for my state.
                    {" "}<a href="https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/1592292" 
                    target="_blank" rel="noreferrer">Peklava</a>{" "} LLC, DBA RateZip NMLS ID 1592292
            </label>
            </p>
        </div>
    )
}

export default PeklavaStatement