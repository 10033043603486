export enum HomePurchaseTimeEnum {
    /// <summary>
    /// A home is planned to be purchased in 30 days
    /// </summary>        
    days30 = 0,
    
    /// <summary>
    /// A home is planned to be purchased in 60 days
    /// </summary>        
    days60 = 1,
    
    /// <summary>
    /// A home is planned to be purchased in 90 days
    /// </summary>        
    days90 = 2,
    
    /// <summary>
    /// A home is planned to be purchased beyond 90 days
    /// </summary>        
    noTimeConstraint = 3
}