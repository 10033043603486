import { QuestionIdEnum } from "../enums/QuestionIdEnum";
import LoanLead from "../LoanLeads/LoanLead";

interface IPathItem {
    componentID:QuestionIdEnum
    captureTime:string
}

//An item that keeps track of the time a lead landed on a question
export class PathItem implements IPathItem {
    componentID: QuestionIdEnum;
    captureTime:string = new Date().toUTCString();

    constructor(questionId:QuestionIdEnum) {
        this.componentID = questionId;
    }

    /**
     * Sets the lead work path and prevents duplicate path items 
     * when users refresh the page or when react rerenders
     * @param lead 
     * @param questionId 
     * @returns 
     */
    public static addPathItemToWorkflow = (lead:LoanLead, questionId:QuestionIdEnum) => {  
        
        const {workflow} = lead.analytics;
        
        const lastIndex = workflow.length - 1;
        
        const lastItem = workflow[lastIndex];

        // If the last item have the same id as the question we are trying to insert that means a page refresh or rerender occurred
        if(lastItem && lastItem.componentID === questionId)
           lead.analytics.workflow.pop();
 
        const questionPath = new PathItem(questionId);
        
        lead.analytics.workflow.push(questionPath)

        lead.analytics.LastPageVisited = questionId;

        return lead;
    }

    public static AddPathItemToBeginningOfWorkFlow = (lead:LoanLead, questionId:QuestionIdEnum) => {                
        
        const questionPath = new PathItem(questionId);
        
        lead.analytics.workflow.unshift(questionPath);

        return lead;
    }
    
    /**
     * Function that checks if a lead have visited the passed in question before. 
     * Returns the index if it has and returns -1 if it has not.
     * @param lead 
     * @param questionId 
     */
    public static hasVisitedQuestion = (lead:LoanLead, questionId:QuestionIdEnum) => {
        return lead.analytics.workflow.findIndex(w => w.componentID === questionId);
    }
}
/**
 * every lead that enter the form should at least contain 
 * the very first question even if skipped
 */
export const defaultPathItem:PathItem = new PathItem(QuestionIdEnum.LoanType);