import { useMemo, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import FormErrorMessage from "../../../components/utilities/FormErrorMessage";
import { DeepCopy } from "../../../helpers/DeepCopy";
import { FormValidator } from "../../../helpers/FormValidator";
import useFieldErrors from "../../../hooks/UseFieldErrors";
import useFields from "../../../hooks/UseFields";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import { basicInfoFields, basicInfoType } from "../../../models/formFields/BasicInformationFields";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";
import { stateDictionary } from "../../../models/stateDictionary/StateDictionary";
import { BasicInfoSchema } from "../../../models/validationSchemas/BasicInfoSchema";
import Location from "../bothPurchaseRefi/Location";

/**
 * Because Bruce and Jerald wanted to remove the property location question for refinance this question was created.
 * This question asks for the leads location instead of their property. This is used instead of basic information because 
 * we need the lead's state in order to decide which TCPA Language to display. If the incorrect TCPA language appears there 
 * could be legal issues because companies like Peklava do not operate in some states. This question should only appear for refinance
 * and should go to the basic information page when answered.
 */
const LeadLocation = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.LeadLocation;

    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.LateMortgage];
    
    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.BasicInfo];   

    return (
        <>
            <Location 
                loanLead={loanLead}
                updateAndStoreLead={updateAndStoreLead}
                header={"What's your primary location?"}
                previousQuestion={previousQuestion}
                nextQuestion={nextQuestion}
                questionId={questionId} 
                isPropertyLocation={false}            
            />
        </>
    )
}

export default LeadLocation;