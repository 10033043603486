import { StringHelper } from "../../helpers/StringHelper";

export class stateDictionary {
    public static states = {    
        "AL": "Alabama",
        "AK": "Alaska",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "DC": "District Of Columbia",
        "FL": "Florida",
        "GA": "Georgia",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "PR": "Puerto Rico",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PA": "Pennsylvania",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming",
    }

    /**
     * Check to see if the passed in code is a valid US state abbreviation/code
     * @param code 
     * @returns 
     */
    public static IsValidCode(code:string):boolean {
        if(StringHelper.isNullOrWhiteSpace(code)) {
            return false;
        }

        code = code.trim().toUpperCase();

        return Object.hasOwn(stateDictionary.states, code);        
    }

    /**
     * Get a valid zip code given a stateCode
     * TODO replace this with an api call
     * @param stateCode 
     */
    public static GetValidZip(stateCode:string) {
        let zip = ""
        switch (stateCode)
        {
            case "AL":
                zip = "35242";
                break;
            case "AK":
                zip = "99654";
                break;
            case "AZ":
                zip = "85001";
                break;
            case "AR":
                zip = "71613";
                break;
            case "CA":
                zip = "90011";
                break;
            case "CO":
                zip = "80013";
                break;
            case "CT":
                zip = "06001";
                break;
            case "DE":
                zip = "19701";
                break;
            case "DC":
                zip = "20001";
                break;
            case "FL":
                zip = "33021";
                break;
            case "GA":
                zip = "30004";
                break;
            case "HI":
                zip = "96718";
                break;
            case "ID":
                zip = "83646";
                break;
            case "IL":
                zip = "60412";
                break;
            case "IN":
                zip = "46033";
                break;
            case "IA":
                zip = "52240";
                break;
            case "KS":
                zip = "66101";
                break;
            case "KY":
                zip = "40475";
                break;
            case "LA":
                zip = "90001";
                break;
            case "ME":
                zip = "04401";
                break;
            case "MD":
                zip = "20850";
                break;
            case "MA":
                zip = "01003";
                break;
            case "MI":
                zip = "48003";
                break;
            case "MN":
                zip = "55411";
                break;
            case "MS":
                zip = "39157";
                break;
            case "MO":
                zip = "63114";
                break;
            case "MT":
                zip = "59901";
                break;
            case "NE":
                zip = "68007";
                break;
            case "NV":
                zip = "89030";
                break;
            case "NH":
                zip = "03842";
                break;
            case "NJ":
                zip = "07096";
                break;
            case "NM":
                zip = "87153";
                break;
            case "NY":
                zip = "10001";
                break;
            case "NC":
                zip = "27007";
                break;
            case "ND":
                zip = "58103";
                break;
            case "OH":
                zip = "43015";
                break;
            case "OK":
                zip = "73003";
                break;
            case "OR":
                zip = "97225";
                break;
            case "PA":
                zip = "15001";
                break;
            case "RI":
                zip = "02860";
                break;
            case "SC":
                zip = "29010";
                break;
            case "SD":
                zip = "57106";
                break;
            case "TN":
                zip = "37013";
                break;
            case "TX":
                zip = "77005";
                break;
            case "UT":
                zip = "84003";
                break;
            case "VT":
                zip = "05401";
                break;
            case "VA":
                zip = "23452";
                break;
            case "WA":
                zip = "98001";
                break;
            case "WV":
                zip = "24701";
                break;
            case "WI":
                zip = "53005";
                break;
            case "WY":
                zip = "82001";
                break;
        }

        return zip
    }
}