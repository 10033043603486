/**
 * Increments values the mortgage balance go through
 * When looking through the source code of the PHP FHA Loan app they used hard coded values
 * to increment the mortgage balance MortgageIncrements are those values
 */
export enum MortgageIncrements {
    /**
     * The increment value to use for mortgage balances below 197,500
     */
    lowIncrement = 5000,

    /**
     * The increment value to use for mortgage balances that are above 197,500 and below 395,000
     */
    midIncrement = 10000,

    /**
     * The increment value to use for mortgage balances that are above 395,000
     */
    upperIncrement = 20000
}