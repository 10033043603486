import { useEffect } from "react";
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useFields from "../../../hooks/UseFields";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { LoanTypeEnum } from "../../../models/enums/LoanTypeEnum";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

const prevNextQuestionObj = {
    purchase: {
        prev: QuestionIdToRoute[QuestionIdEnum.MilitaryService],
        next: QuestionIdToRoute[QuestionIdEnum.VerifyIncome],
    },
    refinance: {
        prev: QuestionIdToRoute[QuestionIdEnum.CurrentInterest],
        next: QuestionIdToRoute[QuestionIdEnum.VerifyIncome],
    }
}

const Bankruptcy = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.Bankruptcy;    

    const [fieldState, , handleBoolChange] = useFields(loanLead.borrower.bankruptcyOrForeclose);

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    useEffect(() => {
        function onFieldStateChange() {
            const hasWentBankrupt = fieldState;

            const lead:LoanLead = DeepCopy.copy(loanLead);

            lead.borrower.bankruptcyOrForeclose = hasWentBankrupt;

            updateAndStoreLead(lead);
        }
        onFieldStateChange();
    },[fieldState]);

    /**
     * Get the prev or next question route/path 
     * based on the current lead's loan type
     */
    const getQuestionPaths = () => {
        const {product} = loanLead;

       return  product === LoanTypeEnum.Purchase ? prevNextQuestionObj.purchase : prevNextQuestionObj.refinance
    }

    const previousQuestion = getQuestionPaths().prev;
    
    const nextQuestion = getQuestionPaths().next;

    return (
        <>
            <div className="question-header-container">
                <h3 className="question-header text-center">Bankruptcy Or Foreclosure <br /> During Past 3 Years?</h3>
            </div>

            <div className="form-element">
                <div className="btn-two-container">
                    <div className="radio-btn-container">
                        <input 
                            checked={fieldState === true} 
                            type="radio" 
                            value="true" 
                            name="bankruptcy" 
                            id="bankruptcyYes" 
                            onChange={handleBoolChange}
                        />
                        <label className="btn-yes btn-question" htmlFor="bankruptcyYes" aria-label="Yes"></label>
                    </div>
                    <div className="radio-btn-container">
                        <input 
                            checked={fieldState === false} 
                            type="radio" 
                            value="false" 
                            name="bankruptcy" 
                            id="bankruptcyNo" 
                            onChange={handleBoolChange}
                        />
                        <label className="btn-no btn-question" htmlFor="bankruptcyNo" aria-label="No"></label>
                    </div>
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default Bankruptcy;