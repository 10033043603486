/**
 * Class for deep copying an object or array
 */
 export class DeepCopy {
    /**
     * Creates a deep copy of a passed in object or array
     * @param inObject 
     * @returns 
     */
    public static copy = (inObject:any) => {        
        if(typeof inObject !== "object" || inObject === null) {
            return inObject;
        }

        let outObject:any, value, key;

        outObject = Array.isArray(inObject) ? [] : {};

        for (key in inObject) {
            value = inObject[key];

            outObject[key] = DeepCopy.copy(value);
        }

        return outObject;
    }
}