import React, { useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { handleQuestionSkipping } from "../../../components/buttons/ContinueButton";
import { TexcorpController } from "../../../controllers/TexcorpController";
import { DeepCopy } from "../../../helpers/DeepCopy";
import { NavigationHelper } from "../../../helpers/NavigationHelper";
import { StringHelper } from "../../../helpers/StringHelper";
import useFieldErrors, { fieldErrorsState } from "../../../hooks/UseFieldErrors";
import useFields from "../../../hooks/UseFields";
import { useGoogleReCAPTCHA } from "../../../hooks/UseGoogleReCAPTCHA";
import useHiddenFields from "../../../hooks/UseHiddenFields";
import useIsLoading from "../../../hooks/UseIsLoading";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { useThirdPartyScripts } from "../../../hooks/UseThirdPartyScripts";
import { LeadBuyerEnum } from "../../../models/enums/LeadBuyerEnum";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import { basicInfoFields } from "../../../models/formFields/BasicInformationFields";
import { BasicInfo } from "../../../models/LoanLeads/basicInfo/BasicInfo";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";
import { stateDictionary } from "../../../models/stateDictionary/StateDictionary";
import BasicInfoStatement from "../privacyStatements/BasicInfoStatement";
import TexcorpStatement from "../privacyStatements/TexcorpStatement";
import '../../../styles/questions/BasicInformationStyles.css';
import { LoanTypeEnum } from "../../../models/enums/LoanTypeEnum";

const texcorpController = new TexcorpController();

/**
 * Basic information that pertains to the user
 * for example user's location zip code, address, etc and not the property
 * @returns 
 */
const BasicInformation = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.BasicInfo;        
    
    const thankYouPagePath = QuestionIdToRoute[QuestionIdEnum.ThankYou];    

    const srTokenRef = useRef<HTMLInputElement>(null);

    const leadIdTokenRef = useRef<HTMLInputElement>(null);
   
    const navigation = useNavigate();
    
    const location = useLocation();

    const googleCaptchaKey = useMemo(() => ( 
        process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY    
        ),[]
    );

    //Get and set default error fields
    const defaultErrorFields = useMemo(() => {
        const fieldsObj:any = {};

        for(let key of Object.keys(basicInfoFields)) {
            fieldsObj[key] = {};
        }
        return fieldsObj;
    }, [basicInfoFields]);

    //Get and set default field values
    const defaultFields = useMemo(() => {
        const fieldsObj:any = {};

        for(let key of Object.keys(basicInfoFields)) {
            fieldsObj[key] = loanLead.borrower.basicInfo[key as keyof BasicInfo]
        }

        return fieldsObj;
    },[basicInfoFields]);    

    const [fieldState, handleChange, , ,resetFormData] = useFields(defaultFields);

    const [isLoading, setIsLoading] = useIsLoading(false);

    const [errorState, , handleErrorChange, validateAllFields]:any = useFieldErrors(defaultErrorFields);    

    useGoogleReCAPTCHA();

    useThirdPartyScripts();

    const [getTrustedHiddenFields] = useHiddenFields();

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    /**
     * Add the invalid input class to the passed in input field if it has any errors
     * @param fieldName 
     * @returns 
     */
    const addInvalidInputStyles = (fieldName:string) => {
        
        const eState = errorState as fieldErrorsState;

        const error = eState[fieldName];

        
        return error.hasErrors ? "invalid-input" : "";
    }

    /**
     * Renders error messages for the passed in field
     * @param fieldName 
     * @returns 
     */
    const renderErrorMessages = (fieldName:string) => {
        if(!errorState[fieldName].errors)
            return;
       return errorState[fieldName].errors.map((error:string) => (            
            <div key={uuidv4()} className="validation-message">
                {error}
            </div>
        ))
    }

    /**
     * Calls the state hooks to update our field state and error state
     * @param e 
     */
    const handleInputChange = (e:React.FormEvent<HTMLInputElement | HTMLSelectElement> ) => {
        if(e.currentTarget.name === basicInfoFields.Phone)
            e.currentTarget.value = StringHelper.formatPhoneInput(e.currentTarget.value);

        handleErrorChange(e);
        handleChange(e);
    }

    /**
     * Handles the google recaptcha validation and returns 
     * if the response was valid or not
     */
    const handleGoogleRecaptcha = async () => {
        //@ts-ignore
        const gCaptcha = window["grecaptcha"];

        const respToken = gCaptcha.getResponse();

        if(StringHelper.isNullOrWhiteSpace(respToken)){
            alert("Please verify you are not a robot.");
            return false;
        }        
        
        setIsLoading(true);

        const isValidRecaptcha = await texcorpController.verifyGoogleRecaptcha();        
        
        setIsLoading(false);

        if(!isValidRecaptcha){
            alert("Failed to verify you are not a robot. Please try again");
            gCaptcha.reset();
            return false;
        }
         
        return true;
    }

    const validateBasicInfoFields = (): [boolean, BasicInfo] => {
        const basicInfoFields:BasicInfo = {...fieldState};        

        parseFields(basicInfoFields);
        
        const isValid = validateAllFields(basicInfoFields);

        if(!isValid) {
            window.scrollTo({top: 0, behavior: "smooth"});
        }

        return [isValid, basicInfoFields];
    }

    /**
     * trims, lower case, and remove non accepted characters from basic info fields
     */
    const parseFields = (basicInfo:BasicInfo) => {
        basicInfo.FirstName = basicInfo.FirstName.trim().toLocaleLowerCase();
        basicInfo.LastName = basicInfo.LastName.trim().toLocaleLowerCase();
        basicInfo.Address = basicInfo.Address.trim().toLocaleLowerCase();
        basicInfo.Email = basicInfo.Email.trim().toLocaleLowerCase();
        basicInfo.BasicInfoCity = basicInfo.BasicInfoCity.trim().toLocaleLowerCase();
        basicInfo.BasicInfoState = basicInfo.BasicInfoState.trim().toLocaleLowerCase();
        basicInfo.BasicInfoZip = StringHelper.removeNonDigits(basicInfo.BasicInfoZip);
        basicInfo.Phone = StringHelper.removeNonDigits(basicInfo.Phone);
        return basicInfo;
    }

    /**
     * Set hidden fields and basic info for the lead
     * @param basicInfoFields 
     * @returns 
     */
    const setLeadBasicInfo = (basicInfoFields:BasicInfo) => {
        const [formToken, certUrl, pingUrl] = getTrustedHiddenFields();
        
        const universalLeadId = leadIdTokenRef.current?.value;            
        
        const srToken = srTokenRef.current?.value;

        const lead:LoanLead = DeepCopy.copy(loanLead);

        lead.borrower.basicInfo = {
            ...basicInfoFields, 
        }; 
        

        //The 1166.. string is not important and could be anything. We don't want to send a empty srtoken to Leadpoint or their api will crash
        lead.thirdPartyRequirements.SRToken = StringHelper.isNullOrWhiteSpace(srToken) ? "1166196087753215325" : srToken as string;
        
        lead.thirdPartyRequirements.TrustedFormCertUrl= certUrl ?? "";
        
        lead.thirdPartyRequirements.TrustedFormPingUrl= pingUrl ?? "";
        
        lead.thirdPartyRequirements.TrustedFormToken= formToken ?? "";
        
        lead.thirdPartyRequirements.UniversalLeadID= universalLeadId ?? "";        
        
        updateAndStoreLead(lead); 

        return lead;
    }

    /**
     * Handles when the form is submitted
     * Not if you change the question order this needs to be updated
     * @param e 
     */
    const handleOnSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        const locationRoute = loanLead.product === LoanTypeEnum.Purchase ? 
                        QuestionIdToRoute[QuestionIdEnum.PropertyLocation] : QuestionIdToRoute[QuestionIdEnum.LeadLocation]

        const path = handleQuestionSkipping(
            QuestionIdToRoute[QuestionIdEnum.BasicInfo], 
            QuestionIdToRoute[QuestionIdEnum.ThankYou], 
            locationRoute,
            loanLead
        );

        if(path !== QuestionIdToRoute[QuestionIdEnum.BasicInfo]) {
            navigation(`/${path}${location.search}`);
            resetFormData();
            return; 
        }
        
        const [isValid, basicInfoFields] = validateBasicInfoFields();

        if(!isValid)
            return;
        
        const isValidCaptcha = await handleGoogleRecaptcha();        

        if(!isValidCaptcha)
            return;

        const lead = setLeadBasicInfo(basicInfoFields);              

        NavigationHelper.pageLoadNavigation(`/${thankYouPagePath}${location.search}`, false,{submitForm: true});

        // await texcorpController.insertLoanLead(lead);
        
        //updates the lead in the database so we don't need to do an insert lead
        // await texcorpController.submitLeadToLeadBuyer(lead);        
    }  

    return (
        <>            
            <div className="question-header-container">
                <h3 className="question-header">Your Basic Information</h3>
            </div>
            <form onSubmit={handleOnSubmit}>
                <div className="basic-info">                    
                    <div className="basic-info-input-container">
                        <div className="basic-info-text-container">
                            <input 
                                id="firstName" 
                                name={basicInfoFields.FirstName}
                                className={`basic-info-input input-border ${addInvalidInputStyles(basicInfoFields.FirstName)}`}
                                type="text" 
                                placeholder="First Name" 
                                value={fieldState.firstName}
                                onChange={handleInputChange}                                                              
                            />
                            <div className="error-container">
                                {renderErrorMessages(basicInfoFields.FirstName)}
                            </div>
                        </div>
                        <div className="basic-info-text-container">
                            <input 
                                id="lastName"
                                name={basicInfoFields.LastName}
                                className={`basic-info-input input-border ${addInvalidInputStyles(basicInfoFields.LastName)}`} 
                                type="text" 
                                placeholder="Last Name"
                                value={fieldState.lastName}
                                onChange={handleInputChange} 
                                onBlur={handleErrorChange}
                            />
                            <div className="error-container">
                                {renderErrorMessages(basicInfoFields.LastName)}
                            </div>
                        </div>

                    </div>

                    <div className="basic-info-input-container full-width">
                        <input 
                            id="address" 
                            name={basicInfoFields.Address}
                            className={`basic-info-input input-border ${addInvalidInputStyles(basicInfoFields.Address)}`} 
                            type="text" 
                            placeholder="Address" 
                            value={fieldState.address}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="error-container">
                        {renderErrorMessages(basicInfoFields.Address)}
                    </div>

                    <div className="basic-info-input-container width-half">
                        <input 
                            id="city"
                            name={basicInfoFields.BasicInfoCity}
                            className={`basic-info-input input-border ${addInvalidInputStyles(basicInfoFields.BasicInfoCity)}`}
                            type="text" 
                            placeholder="City" 
                            value={fieldState.BasicInfoCity}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="error-container">                        
                        {renderErrorMessages(basicInfoFields.BasicInfoCity)}                        
                    </div>

                    <div className="basic-info-input-container width-half">
                        <select 
                            name={basicInfoFields.BasicInfoState} 
                            className={`basic-info-input input-border ${addInvalidInputStyles(basicInfoFields.BasicInfoState)}`} 
                            value={fieldState.BasicInfoState}
                            onChange={handleInputChange}
                            onBlur={handleErrorChange}
                        >
                            <option value={""} disabled defaultValue={""}>--Select--</option>
                            {
                                Object.entries(stateDictionary.states).map(([key, value]) => (
                                    <option 
                                        key={uuidv4()} 
                                        value={key}
                                    >
                                        {value}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="error-container">
                        {renderErrorMessages(basicInfoFields.BasicInfoState)}
                    </div>

                    <div className="basic-info-input-container width-half">
                        <input 
                            id="zip"
                            name={basicInfoFields.BasicInfoZip} 
                            className={`basic-info-input input-border ${addInvalidInputStyles(basicInfoFields.BasicInfoZip)}`}
                            type="number" 
                            placeholder="Zip" 
                            value={fieldState.BasicInfoZip}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="error-container-multi">
                        {renderErrorMessages(basicInfoFields.BasicInfoZip)}
                    </div>

                    <div className="basic-info-input-container">
                        <div className="basic-info-text-container">
                            <input 
                                id="phone"  
                                name={basicInfoFields.Phone}
                                className={`basic-info-input input-border ${addInvalidInputStyles(basicInfoFields.Phone)}`} 
                                type="text" 
                                placeholder="Phone Number" 
                                value={fieldState.Phone}
                                onChange={handleInputChange}
                            />
                            <div className="error-container-multi">                                
                                {renderErrorMessages(basicInfoFields.Phone)}
                            </div>
                        </div>
                        <div className="basic-info-text-container">
                            <input 
                                id="email"  
                                name={basicInfoFields.Email} 
                                className={`basic-info-input input-border ${addInvalidInputStyles(basicInfoFields.Email)}`}
                                type="text" 
                                placeholder="fhaloan@example.com"
                                value={fieldState.Email}
                                onChange={handleInputChange}
                            />
                            <div className="error-container-multi">
                                {renderErrorMessages(basicInfoFields.Email)}
                            </div>
                        </div>
                    </div>
                    <TexcorpStatement/>
                </div>

                <div id="Grecaptcha" className="g-recaptcha" data-sitekey={googleCaptchaKey}></div>
                        
                <div className="basic-info-btn-container">
                    <button 
                        disabled={isLoading}
                        className="btn-basic-info" 
                        type="submit" 
                        aria-label="See Results Button"                        
                    >                        
                    </button>                    
                </div>
        
                <BasicInfoStatement loanLead={loanLead} />                    

                {loanLead.leadBuyer === LeadBuyerEnum.LeadPoint && (
                    <input ref={srTokenRef} type="hidden" name="SR_TOKEN" />                
                )}
                <input ref={leadIdTokenRef} type="hidden" id="leadid_token" name="universal_leadid" />
                <input type="hidden" name="xxTrustedFormToken" id="xxTrustedFormToken_0"/>
                <input type="hidden" name="xxTrustedFormCertUrl" id="xxTrustedFormCertUrl_0"/>
                <input type="hidden" name="xxTrustedFormPingUrl" id="xxTrustedFormPingUrl_0"/> 
            </form>
        </>
    )
}

export default BasicInformation;