import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DeepCopy } from "../../helpers/DeepCopy";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import { StringToClientTypeEnum } from "../../models/enums/ClientTypeEnum";
import { LoanTypeEnum, queryStringProductToEnum } from "../../models/enums/LoanTypeEnum";
import { QuestionIdEnum } from "../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../models/enums/QuestionIdToRoute";
import { ppcidDictionary } from "../../models/LoanLeads/analytics/PpcidDictionary";
import LoanLead from "../../models/LoanLeads/LoanLead";
import { PathItem } from "../../models/PathItems/PathItem";
import { LoanLeadProps } from "../../models/propTypes/LoanLeadProps";

/**
 * The home page is technically the last question a user left off on since there isn't a home page
 * This component handles the redirect to that last question current question
 * @returns 
 */
const Home = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;
    // const loanLead = useContext(LoanLeadContext);
    const navigation = useNavigate();
    const location = useLocation();

  /**
   * Updates the lead with information about what website they came from
   * Note: ppcid is determined by cid because they are the same thing; 
   * loan_purpose is the same as product. These can be removed from the external fha (FHALOAN.com) sites url query params
   */
	const updateClientInformation = () => {
		const queryParams = new URLSearchParams(window.location.search);
		
		const cid = queryParams.get("cid") || queryParams.get("CID");   
	
		const lead:LoanLead = DeepCopy.copy(loanLead);
		
		const convertedCID = StringToClientTypeEnum(cid);
	
		lead.analytics.cid = convertedCID;
	
		lead.analytics.ppcid = ppcidDictionary[convertedCID].toString();  

		return lead;
	}

    /**
     * Updates the leads product and set the workflow
     */
    const updateLeadProduct = (product:LoanTypeEnum, lead:LoanLead) => {			
		  lead.product = product;
		
		  lead = PathItem.addPathItemToWorkflow(lead, QuestionIdEnum.LoanType);
      
      return lead;
    }

    /**
     * Handles how navigating to the first question should go
     */
    const handleFirstQuestionNavigation = (lead:LoanLead, product:LoanTypeEnum | null) => {
        let route = QuestionIdToRoute[QuestionIdEnum.LoanType];
        
        //Product query string is not set navigate to the first question
        if(product != null) {
          route = QuestionIdToRoute[QuestionIdEnum.CreditScore];
          lead = updateLeadProduct(product, lead);
        }
      
        updateAndStoreLead(lead);   

        return route;
    }

    /**
     * Navigate the user to the first question or to their current question.
     */
    const navigateToCurrentQuestion = async (lead:LoanLead) => {		        
        const {analytics:{workflow, IPAddress}} = lead;              
          
        let route = QuestionIdToRoute[QuestionIdEnum.LoanType];

        const product = checkIfProductQueryString();

        //If workflow is empty/undefined navigate to the first question
        if(!workflow || workflow.length <= 0) {       
          route = handleFirstQuestionNavigation(lead, product);
          NavigationHelper.pageLoadNavigation(`/${route}${location.search}`, true);                    
          return;            
        }

        if(product === lead.product) {
          const lastIndex = workflow.length - 1;

          const {componentID} = workflow[lastIndex];        

          //could be potential 0 so that is why we do a null/undefine check instead                   
          if(componentID != null) {
            route =  QuestionIdToRoute[componentID];
          }

        } else {
          route = handleFirstQuestionNavigation(lead, product);
        }        
        NavigationHelper.pageLoadNavigation(`/${route}${location.search}`, true);
        // navigation(`/${route}${location.search}`, {replace: true});
    }

    /**
     * Checks if the product query string is available
     */
    const checkIfProductQueryString = () => {    	
      const queryParams  = new URLSearchParams(window.location.search);	
        
      const product = queryParams.get("product");	
        
      if(!product)
      
      return null;	
        
      const loanType = queryStringProductToEnum(product);	
        
      return loanType;
    }    

    useEffect(() => {
      function onComponentMount(){
        
		    const lead = updateClientInformation();

        navigateToCurrentQuestion(lead);
      }

      onComponentMount();
    }, [])
    

    return (
        <>        
        </>
    )
}

export default Home;