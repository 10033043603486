import { useEffect, useState } from 'react';
import NavigationButtons from '../../../components/buttons/NavigationButtons';
import Typeahead from '../../../components/inputs/typeAhead/Typeahead';
import { GeoapifyController } from '../../../controllers/GeoapifyController';
import { DeepCopy } from '../../../helpers/DeepCopy';
import { StringHelper } from '../../../helpers/StringHelper';
import useOnQuestionMount from '../../../hooks/UseOnQuestionMount';
import { QuestionIdEnum } from '../../../models/enums/QuestionIdEnum';
import { QuestionIdToRoute } from '../../../models/enums/QuestionIdToRoute';
import LoanLead from '../../../models/LoanLeads/LoanLead';
import { LoanLeadProps } from '../../../models/propTypes/LoanLeadProps';
import { GeoapifyAutocomplete } from '../../../models/responses/GeoapifyResponse';
import { stateDictionary } from '../../../models/stateDictionary/StateDictionary';
import Location from './Location';
import '../../../styles/errors/ErrorStyles.css';
import '../../../styles/questions/PropertyLocation.css';

export type propertyInput = {
    state:string
    city:string
    zip:string
}

export type propertyLocationState = {
    property:propertyInput
    showErrors:boolean    
    autoCompleteData:GeoapifyAutocomplete[]
    isLoading:boolean    
}

const geoapifyController = new GeoapifyController();

/**
 * Property Location question that get the 
 * city, zip, and location from the lead 
 * This component has been changed to wrap the 
 * location component due to adding the lead location question.
 * Make sure this question updates the property location and not the lead location (Basic info will update that).
 * It is important that the prop isPropertyLocation is true
 * @returns 
 */
const PropertyLocation = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.PropertyLocation

    const previousQuestion =QuestionIdToRoute[QuestionIdEnum.LateMortgage];
    
    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.BasicInfo];

    return (
        <>
            <Location
                header={"Property Location"}
                loanLead={loanLead}
                updateAndStoreLead={updateAndStoreLead}                
                previousQuestion={previousQuestion}
                nextQuestion={nextQuestion}
                questionId={questionId} 
                isPropertyLocation={true}   
            />
        </>
    )
}

export default PropertyLocation;
