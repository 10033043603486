import { GeoapifyAutocomplete } from "../models/responses/GeoapifyResponse";
import { GeoapifyService } from "../services/GeoapifyService";
import {v4 as uuidv4} from 'uuid';

export class GeoapifyController {
    private geoapifyService = new GeoapifyService();

    private formatCountry = (country:string) => {
        return country === "us" ? "USA" : country.toUpperCase();
    }

    /**
     * Performs an auto complete city search using GeoApify API
     * @param searchText 
     * @param limit 
     * @param type 
     * @returns 
     */
    public autocompleteSearch = async (searchText:string, limit:number, type:string) => {
        const geoapifyData = await this.geoapifyService.getCityAutoComplete({searchText, limit, type})
        
        const autocompleteData = geoapifyData.results
        .filter(res => res.country_code === "us" && res.city)
        .map(res => {
            const autocomplete:GeoapifyAutocomplete = {
                id: uuidv4(),
                country: this.formatCountry(res.country_code),
                city: res.city,
                stateCode: res.state_code,
                postCode: res.postcode //TODO check if postcode
            }
            return autocomplete;
        });
        
        //sorting in alphabetical order
        autocompleteData.sort((a, b) => {
            if (a.city > b.city)
                return 1
            
            if(a.city < b.city)
                return -1;
            return 0
        });

       return autocompleteData.reduce((uniques:GeoapifyAutocomplete[], current:GeoapifyAutocomplete) => {
    
            if(!uniques.some(geoObj => geoObj.city === current.city && geoObj.stateCode === current.stateCode)){
            
                uniques.push(current)
            }
            
            return uniques;
        }, []) as GeoapifyAutocomplete[]
    }
}