/**
 * Lead buyer the lead is sent to when completing the form
 */
export enum LeadBuyerEnum  {
    Unknown = 0,
    LeadPoint = 1,
    Peklava = 2
}

export const LeadBuyerToText = {
    [LeadBuyerEnum.Unknown]: "Unknown",
    [LeadBuyerEnum.LeadPoint]: "Leadpoint",
    [LeadBuyerEnum.Peklava]: "Peklava",
} 