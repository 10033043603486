import { useEffect } from "react";
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useFields from "../../../hooks/UseFields";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

const BorrowCash = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.BorrowCash;

    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.MortgageBalance];

    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.PropertyDescription];        

    const [formData,,handleBoolChange] = useFields(loanLead.borrower.borrowCash)

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    useEffect(() => {
        function onFormDataChange() {
            const lead:LoanLead = DeepCopy.copy(loanLead);

            lead.borrower.borrowCash = formData;

            updateAndStoreLead(lead);
        }

        onFormDataChange();
    }, [formData])

    return (
        <>
            <div className="question-header-container">
                <h3 className="question-header text-center">Want To Borrow $5,000 <br /> Or More Additional Cash?</h3>
            </div>

            <div className="form-element">
                <div className="btn-two-container">
                    <div className="radio-btn-container">
                        <input 
                            id="borrowCashYes" 
                            name="borrowCash" 
                            checked={formData === true} 
                            type="radio" 
                            value="true" 
                            onChange={handleBoolChange} 
                        />
                        <label className="btn-yes btn-question" htmlFor="borrowCashYes" aria-label="Yes"></label>
                    </div>

                    <div className="radio-btn-container">
                        <input 
                            id="borrowCashNo" 
                            name="borrowCash" 
                            checked={formData === false} 
                            type="radio" 
                            value="false" 
                            onChange={handleBoolChange} 
                        />
                        <label className="btn-no btn-question" htmlFor="borrowCashNo" aria-label="Yes"></label>
                    </div>
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default BorrowCash;