import { useEffect } from "react";
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import { DeepCopy } from "../../../helpers/DeepCopy";
import { QuestionHelper } from "../../../helpers/QuestionHelper";
import useFields from "../../../hooks/UseFields";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { LateMortgageEnum } from "../../../models/enums/LateMortgageAmountEnum";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

/**
 * Question concerning the amount of late payments a lead has.
 * This is in the borrower category in the lead class.
 * @returns 
 */
const LateMortgagePayments = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.LateMortgage;

    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.VerifyIncome];
    
    const nextQuestion = QuestionHelper.GetPurchaseOrRefiNextQuestion(loanLead.product, QuestionIdEnum.PropertyLocation, QuestionIdEnum.LeadLocation);   

    const [fieldState, , ,handleEnumChange] = useFields(loanLead.borrower.lateMortgageAmount);

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    //When fieldState change we need to update our global loanLead
    useEffect(() => {
        function onFieldStateChange() {
            const lateMortgage = fieldState;

            const lead:LoanLead = DeepCopy.copy(loanLead);

            lead.borrower.lateMortgageAmount = lateMortgage;

            updateAndStoreLead(lead);
        }
        onFieldStateChange();
    }, [fieldState]);

    /**
     * Check to see which radio button should be selected
     */
    const checkedIfSelected = (lateMortgage:LateMortgageEnum) => {
        return lateMortgage === fieldState
    }

    return (
        <>
            <div className="question-header-container">
                <h3 className="question-header-small">Late Payments?</h3>
                <h3 className="question-header-medium">Late Payments Recently?</h3>
                <h3 className="question-header text-center">Late Mortgage Payments 
                <br /> In Last 12 Months?</h3>
            </div>

            <div className="form-element">
                <div className="btn-container-3">
                    <div className="custom-radio-container">
                        <input 
                            checked={checkedIfSelected(LateMortgageEnum.none)} 
                            type="radio" 
                            value={LateMortgageEnum.none} 
                            id="lateMortgageNone" 
                            name="lateMortgage" 
                            onChange={(e) => handleEnumChange(e, LateMortgageEnum)}
                        />
                        <label className="btn-none btn-three-question" htmlFor="lateMortgageNone" aria-label="None"></label>
                    </div>

                    <div className="custom-radio-container">
                        <input 
                            checked={checkedIfSelected(LateMortgageEnum.one)} 
                            type="radio" 
                            value={LateMortgageEnum.one} 
                            id="lateMortgageOne" 
                            name="lateMortgage" 
                            onChange={(e) => handleEnumChange(e, LateMortgageEnum)}
                        />
                        <label className="btn-one btn-three-question" htmlFor="lateMortgageOne" aria-label="One"></label>
                    </div>

                    <div className="custom-radio-container">
                        <input 
                            checked={checkedIfSelected(LateMortgageEnum.twoOrMore)} 
                            type="radio" 
                            value={LateMortgageEnum.twoOrMore} 
                            id="lateMortgageTwo" 
                            name="lateMortgage" 
                            onChange={(e) => handleEnumChange(e, LateMortgageEnum)}
                        />
                        <label className="btn-two-more btn-three-question" htmlFor="lateMortgageTwo" aria-label="Two or More"></label>
                    </div>
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default LateMortgagePayments;