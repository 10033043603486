export enum ExistingLoanEnum {
    /// <summary>A FHA type loan</summary>
    FHA = 0,
    
    /// <summary>A VA type loan</summary>
    VA = 1,
    
    /// <summary>Conventional loan</summary>
    Conventional = 2,
    
    /// <summary>The loan is neither FHA nor VA</summary>
    Other = 3
}