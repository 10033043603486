import { AxiosResponse } from "axios";
import { GoogleReCAPTCHAResponse } from "../models/googleReCAPTCHA/GoogleReCAPTCHAResponse";
import LoanLead from "../models/LoanLeads/LoanLead";
import { fieldToValueType } from "../models/requests/PatchJSONDocument";
import { LeadVendorResponse } from "../models/responses/LeadVendorResponse";
import { LoanLeadResponse } from "../models/responses/LoanLeadResponse";
import { TexcorpService } from "../services/TexcorpService";

/**
 * Class for communicating front end components to services
 */
export class TexcorpController {
    private texcorpService = new TexcorpService();

    /**
     * Inserts a lead into the leadDatabase
     * @param lead 
     */
    public insertLoanLead = async (lead:LoanLead) => {
        const response:AxiosResponse<LoanLeadResponse> = await this.texcorpService.insertLead(lead);

        return response.data.result;
    }
    
    /**
     * Updates or Patches a single field without updating the entire loan lead
     * @param lead 
     * @param fieldToUpdate 
     * @param value 
     * @returns 
     */
    public updateLoanLead = async (lead:LoanLead, fieldToUpdate:string, value:any) => {
        const fieldValueObj:fieldToValueType[] = [
            {
                field: "/" + fieldToUpdate, 
                value: value
            }
        ];

        const response:AxiosResponse = await this.texcorpService.updateLeadFields(lead, fieldValueObj);

        return response.data;
    }

    /**
     * submits to a lead to one of the lead buyers  
     * @param lead 
     */
    public submitLeadToLeadBuyer = async (lead:LoanLead) => {

        const response:AxiosResponse<LeadVendorResponse> = await this.texcorpService.submitToLeadBuyer(lead);
        
        const {claimId, leadStatus, leadId} = response.data.result;

        lead.analytics.leadStatus = leadStatus;
        
        lead.thirdPartyRequirements.leadID = leadId;
        
        lead.thirdPartyRequirements.ClaimID = claimId;

        return lead;
    }

    /**
     * Patch/Partially update the lead's creditScoreClicks
     */
    public updateClickedCreditScore = async (lead:LoanLead, clicked:boolean) => {
        const fieldToValues:fieldToValueType[] = [
            {field: "/clickedCreditScore", value: clicked}
        ]
        const response:AxiosResponse = await this.texcorpService.updateLeadFields(lead, fieldToValues);

        return response.data;
    }

    /**
     * Updates the completed form field and update its workflow
     * @param lead 
     * @param CompletedForm 
     * @returns 
     */
    public updatedCompletedForm = async (lead:LoanLead, CompletedForm:boolean) => {
        const fieldToValues:fieldToValueType[] = [
            {field: "/CompletedForm", value: CompletedForm},
            {field: "/workflow", value: lead.analytics.workflow},
            {field: "/lastPageVisited", value: lead.analytics.LastPageVisited},
        ]
        const response:AxiosResponse = await this.texcorpService.updateLeadFields(lead, fieldToValues);

        return response.data;
    }

    /**
     * Verifies the google recaptcha request
     * @returns 
     */
    public verifyGoogleRecaptcha = async (): Promise<boolean | Error> => {
        const response:AxiosResponse<GoogleReCAPTCHAResponse> | null | Error = await this.texcorpService.verifyGoogleRecaptcha();

        if(!response)
            return false;

        if(response instanceof Error) {
            return response;
        }

        console.log(response)

        return response.data.resultObject.success;        
    }
}