import { QuestionIdEnum } from "./QuestionIdEnum";

/**
 * Object that holds page routes.
 * This is used to help with navigate so we can change routes in only one place
 */
export const QuestionIdToRoute = {
    [QuestionIdEnum.LoanType]: "loan_type", 
    [QuestionIdEnum.PropertyLocation]: "property_location", 
    [QuestionIdEnum.CreditScore]: "credit_rating", 
    [QuestionIdEnum.PropertyValue]: "home_value", 
    [QuestionIdEnum.DownPayment]: "down_payment", 
    [QuestionIdEnum.MortgageBalance]: "mortgage_balance", 
    [QuestionIdEnum.MinDownPayment]: "minimum_down_payment", 
    [QuestionIdEnum.BorrowCash]: "borrow_cash", 
    [QuestionIdEnum.PropertyDescription]: "property_description", 
    [QuestionIdEnum.FoundHome]: "found_home", 
    [QuestionIdEnum.WantAgent]: "agent_match", 
    [QuestionIdEnum.MilitaryService]: "military_service", 
    [QuestionIdEnum.WhenBuyHome]: "home_purchase_time", 
    [QuestionIdEnum.ExistLoan]: "existing_loan", 
    [QuestionIdEnum.CurrentInterest]: "current_interest_rate", 
    [QuestionIdEnum.Bankruptcy]: "bankruptcy_foreclosure", 
    [QuestionIdEnum.VerifyIncome]: "verify_income", 
    [QuestionIdEnum.LateMortgage]: "late_mortgages",
    [QuestionIdEnum.LeadLocation]: "lead_location", 
    [QuestionIdEnum.BasicInfo]: "basic_information", 
    [QuestionIdEnum.ThankYou]: "thank_you", 
}