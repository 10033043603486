import { useEffect, useMemo } from "react";
import { v4 as uuidv4 } from 'uuid';
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import Slider, { sliderTrackClass } from "../../../components/sliders/Slider";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useFields from "../../../hooks/UseFields";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import { defaultCurrentLoanFields } from "../../../models/LoanLeads/currentLoan/CurrentLoanType";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

const currentInterestRates = [
    3.5, 
    4.5, 
    4.75, 
    5, 
    5.25 
]

const CurrentInterestRate = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.CurrentInterest;

    const maxSliderLength = currentInterestRates.length - 1;

    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.ExistLoan];
    
    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.Bankruptcy];

    const defaultSliderIndex = useMemo(() => {
        const currentInterestRate = loanLead.currentLoan.currentInterestRate ? loanLead.currentLoan.currentInterestRate : 3.5;
        return currentInterestRates.findIndex(val => val === currentInterestRate);
    },[loanLead.currentLoan.currentInterestRate]);


    const [formData, handleChange] = useFields({
        currentInterestRate: loanLead.currentLoan.currentInterestRate ?? defaultCurrentLoanFields.currentInterestRate
    });

    const leadCopy = useMemo(() => {
        const lead:LoanLead = DeepCopy.copy(loanLead);

        lead.currentLoan.currentInterestRate = formData.currentInterestRate;

        return lead;    
    },[]);


    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    useEffect(() => {
        function onFormDataChange() {        
            updateCurrentInterestRate(parseFloat(formData.currentInterestRate))
        }
        onFormDataChange();
    }, [formData.currentInterestRate])

    const updateCurrentInterestRate = (value:number) => {
        const lead:LoanLead = DeepCopy.copy(loanLead);

        lead.currentLoan.currentInterestRate = value;

        updateAndStoreLead(lead);

    }

    const updateInterestRateBySlider = (index:number) => {
        const interestRate = currentInterestRates[index];

        updateCurrentInterestRate(interestRate);
    }

    const displayInterest = (val: number) => {
        
        if(val === currentInterestRates[0]) 
            return `Below or equal to 4.5%`

        const endIndex = currentInterestRates.length - 1;

        if(val === currentInterestRates[endIndex])
            return `Above 5%`

        return `${val}%`
    }

    const sliderDisplay = (index:number) => {
        const interestRate = currentInterestRates[index];

        return displayInterest(interestRate);
    }

    return (
        <>
            <div className="question-header-container">
                <h3 className="question-header-small">Current Interest Rate?</h3>
                <h3 className="question-header-medium">What's Your Interest Rate?</h3>
                <h3 className="question-header">What is Your Current Interest Rate?</h3>
                <span className="text-center tip">(It's OK to estimate.)</span>
            </div>

            <div className="form-element select-mobile-container">
                <select                                      
                    value={parseFloat(formData.currentInterestRate)} 
                    className="input-select-mobile input-border" 
                    onChange={handleChange}
                    name="currentInterestRate"
                >
                    {
                        currentInterestRates.map((val) => (
                            <option key={uuidv4()} value={val}>{displayInterest(val)}</option>
                        ))
                    }
                </select>

                <Slider 
                    sliderTrack={sliderTrackClass.default}
                    showProgress={true}
                    min={0}
                    max={maxSliderLength}
                    defaultValue={defaultSliderIndex}  
                    displayInSlider={sliderDisplay}     
                    setValue={updateInterestRateBySlider}     
                />
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default CurrentInterestRate;