export class StringHelper {
    /**
     * Checks if a string is null, undefined or contains whitespace
     * @param str 
     * @returns 
     */
    public static isNullOrWhiteSpace = (str:string|undefined|null):boolean => {
        if(str === "")
            return true;

        return str == null || str.match(/^ *$/) !== null
    }

    public static convertEmptyStringToNull = (str:string) => {
        
        if(StringHelper.isNullOrWhiteSpace(str))
            return null;

        return str;
    }

    /**
     * format text into US dollars format. i.e: $1,000
     */
    public static formatNumberToDollars = (str:string) => {
        return '$' + parseInt(str).toLocaleString('en-US');        
    }

    /**
     * remove everything from a string except for digits
     */
    public static removeNonDigits = (str:string) => {
        let digitOnlySting = "";
        for(let char of str) {
            const digit = parseInt(char);
            if(!isNaN(digit)) {
                digitOnlySting = digitOnlySting + `${digit}`;
            }
        }
        return digitOnlySting;
    }

    /**
     * formats input into a US phone format
     * @param phoneStr 
     * @returns 
     */
    public static formatPhoneInput = (phoneStr:string) => {        
        
        // Getting an array of the sections of the phone numbers 
        const phoneArr = phoneStr.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        
        //@ts-ignore
        // Update the phone number with a US formatted phone number
        phoneStr = !phoneArr[2] ? phoneArr[1] : '(' + phoneArr[1] + ') ' + phoneArr[2] + (phoneArr[3] ? '-' + phoneArr[3] : '');

        return phoneStr
    }

    /**
     * Capitalize the first letter in a string
     * @param str 
     * @returns 
     */
    public static capitalizeString = (str:string) => {
        if(StringHelper.isNullOrWhiteSpace(str))
            return "";

        return str[0].toUpperCase() + str.slice(1, str.length);
    }

    /**
     * Capitalize every word or letter in a string
     * i.e. salt lake city => Salt Lake City
     * @param str 
     */
    public static capitalizeMultiString = (str:string) => {
        if(StringHelper.isNullOrWhiteSpace(str))
            return "";

        return str.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    }
}