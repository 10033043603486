import { useEffect } from "react";
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useFields from "../../../hooks/UseFields";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { ExistingLoanEnum } from "../../../models/enums/ExistingLoanEnum";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

const ExistingLoan = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.ExistLoan

    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.MilitaryService]

    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.CurrentInterest]    

    const [formData, , ,handleEnumChange] = useFields(loanLead.currentLoan.existingLoan)

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    useEffect(() => {
        function onComponentMount() {
            const lead:LoanLead = DeepCopy.copy(loanLead);

            lead.currentLoan.existingLoan = formData;

            updateAndStoreLead(lead);
        }
        onComponentMount();
    },[formData])
    
    return (
        <>
            <div className="question-header-container">
                <h3 className="question-header-medium">What Type of Existing Loan?</h3>
                <h3 className="question-header text-center">What Type of Existing 
                <br /> Loan Do You Have?</h3>
            </div>

            <div className="form-element">
                <div className="btn-container-3">
                    <div className="custom-radio-container">
                        <input 
                            id="fhaLoan" 
                            name="existingLoanType" 
                            checked={formData === ExistingLoanEnum.FHA} 
                            type="radio" 
                            value={ExistingLoanEnum.FHA} 
                            onChange={(e) => handleEnumChange(e, ExistingLoanEnum)} 
                        />
                        <label className="btn-fha-loan btn-three-question" htmlFor="fhaLoan" aria-label="FHA Loan"></label>
                    </div>

                    <div className="custom-radio-container">
                        <input 
                            id="vaLoan" 
                            name="existingLoanType" 
                            checked={formData === ExistingLoanEnum.VA} 
                            type="radio" 
                            value={ExistingLoanEnum.VA} 
                            onChange={(e) => handleEnumChange(e, ExistingLoanEnum)}
                        />
                        <label className="btn-va-loan btn-three-question" htmlFor="vaLoan" aria-label="Va Loan"></label>
                    </div>

                    <div className="custom-radio-container">
                        <input 
                            id="other" 
                            name="existingLoanType" 
                            checked={formData === ExistingLoanEnum.Other} 
                            type="radio" 
                            value={ExistingLoanEnum.Other} 
                            onChange={(e) => handleEnumChange(e, ExistingLoanEnum)}
                        />
                        <label className="btn-other-loan btn-three-question" htmlFor="other" aria-label="Other"></label>
                    </div>
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default ExistingLoan;