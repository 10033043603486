import { StringHelper } from "../../helpers/StringHelper";

export const defaultPropertyValueIndex = 6;

/**The actual property values that are submitted to the API */
export const propertyValues:number[] = [
    100000,
    125000,
    150000,
    175000,
    200000,
    225000,
    250000,
    275000,
    300000,
    325000,
    350000,
    375000,
    400000,
    425000,
    450000,
    475000,
    500000,
    525000,
    550000,
    575000,
    600000,
    625000,
    650000,
    675000,
    700000,
    725000,
    750000,
    775000,
    800000,
    825000,
    850000,
    875000,
    900000,
    925000,
    950000,
    975000,
    1000000
]

/**
 * Get the UI display strings for property values
 * 
 */
export const getUiPropertyValues = () => {
    const uiPropertyValues:any = {};

    for(let i = 0; i < propertyValues.length; i++) {
        const value = propertyValues[i];
        
        const currencyVal = StringHelper.formatNumberToDollars(value.toString());            

        if(i === propertyValues.length - 1) {
            uiPropertyValues[value] = `Over ${currencyVal}`;    
            break;        
        }  
        
        const nextValue = propertyValues[i + 1];
        
        const nextCurrencyVal = StringHelper.formatNumberToDollars(nextValue.toString());
        
        uiPropertyValues[value] = `${currencyVal} - ${nextCurrencyVal}`;
              
    }
    return uiPropertyValues;
}