import React, { useMemo, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import Slider, { sliderTrackClass } from "../../../components/sliders/Slider";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { MortgageBalanceFormatter, MortgageBalanceRange } from "../../../models/mortgageBalance/MortgageBalance";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

type MortgageBalanceState = {
    selectedMortgageBalance:number     
}

const MortgageBalance = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.MortgageBalance;
    
    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.PropertyValue];
    
    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.BorrowCash];    
    
    /**
     * A list of mortgage ranges that change based on the property value
     */
    const mortgageBalanceRanges = useMemo(() => {
        const defaultMinMortgageBalance = 50000;
        
        const {propertyValue} = loanLead;
        
        const initialRange = new MortgageBalanceRange(propertyValue, defaultMinMortgageBalance);

        const mortgageFormatter =  new MortgageBalanceFormatter(initialRange);

        return mortgageFormatter.mortgageRangeList;

    },[loanLead, loanLead.propertyValue]);

    /**
     * Get the default index for the slider
     * @returns 
     */
    const getDefaultIndex = () => {
        if(loanLead.currentLoan.mortgageBalance === 0) {
            return Math.floor(mortgageBalanceRanges.length/2)
        }         

        // Finding the index of the lead's current mortgage balance in the mortgage balance list,
        // so we can set the slider and dropdown (mobile)
        let index = mortgageBalanceRanges.findIndex(val => val.hasOwnProperty(loanLead.currentLoan.mortgageBalance));

        // Check if the index was found or not
        if(index === -1)
            // if the index was not found that means the mortgage balance value is greater than the property value
            // So we set the index to the last item in the list (the property value)
            index = mortgageBalanceRanges.length - 1;
        
        return index;
    }

    const defaultState:MortgageBalanceState = {
        selectedMortgageBalance: loanLead.currentLoan.mortgageBalance,
    }

    const [state, setState] = useState(defaultState);    

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    const updateMortgageBalance = (balance:number) => {
        setState(prevState => ({
            ...prevState,
            selectedMortgageBalance:balance
        }));

        const lead:LoanLead = DeepCopy.copy(loanLead);

        lead.currentLoan.mortgageBalance = balance;

        updateAndStoreLead(lead);
    }

    /**
     * Get the display text for a mortgage balance to display in the
     * readonly input
     */
    const getMortgageRangeDisplay = (currentIndex:number) => {
        
        if(!mortgageBalanceRanges[currentIndex])
            return 

        return Object.values(mortgageBalanceRanges[currentIndex])[0] as string;
    }

    /**
     * Updates the mortgage balance when the slider index changes
     * @param index 
     */
    const handleSliderChange = (index:number) => {
        setState(prevState => ({
            ...prevState,
            currentIndex:index
        }));
        const mortgageBalance = parseInt(Object.keys(mortgageBalanceRanges[index])[0]);
        
        updateMortgageBalance(mortgageBalance);
    }

    /**
     * converts the selected value to an int and update the mortgage balance
     * @param e 
     */
    const handleSelectInput = (e:React.FormEvent<HTMLSelectElement>) => {
        const val = parseInt(e.currentTarget.value);

        updateMortgageBalance(val);
    }

    return (
        <>
            <div className="question-content-container">
                <div className="question-header-container">
                    <h3 className="question-header-small">Mortgage Balance?</h3>
                    <h3 className="question-header-medium">Your Mortgage Balance?</h3>
                    <h3 className="question-header">What Is Your Mortgage Balance?</h3>
                    <span className="text-center tip">(It's OK to estimate. Include first and second mortgages plus any equity loans or credit lines.)</span>
                </div>
            <div className="form-element select-mobile-container">
                <select value={state.selectedMortgageBalance} className="input-select-mobile input-border" onChange={handleSelectInput}>
                    <option disabled>Mortgage Balance</option>
                    {
                        mortgageBalanceRanges.map((range, index) => (
                            <option key={uuidv4()} value={Object.keys(range)[0]}>
                                {`${Object.values(range)[0]}`}
                            </option>
                        ))
                    }
                </select>

                <Slider 
                    sliderTrack={sliderTrackClass.default}
                    showProgress={true}
                    min={0}
                    max={mortgageBalanceRanges.length - 1}
                    defaultValue={getDefaultIndex()}
                    setValue={handleSliderChange}
                    displayInSlider={getMortgageRangeDisplay}
                />
            </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default MortgageBalance;

