import { useEffect } from "react";
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useFields from "../../../hooks/UseFields";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

const VerifyIncome = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.VerifyIncome;    

    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.Bankruptcy];
    
    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.LateMortgage];
    
    const [formData, , handleBoolChange] = useFields(loanLead.newLoan.verifyIncome);    

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    useEffect(() => {
        function onFieldStateChange() {
            const canVerifyIncome = formData;

            const lead:LoanLead = DeepCopy.copy(loanLead);

            lead.newLoan.verifyIncome = canVerifyIncome;

            updateAndStoreLead(lead);
        }
        onFieldStateChange();
    },[formData]);
    
    return (
        <>
            <div className="question-header-container">
                <h3 className="question-header">Can You Verify Your Income?</h3>
            </div>

            <div className="form-element">
                <div className="btn-two-container">
                    <div className="radio-btn-container">
                        <input 
                            checked={formData === true} 
                            type="radio" 
                            value="true" 
                            name="verifyIncome" 
                            id="incomeYes" 
                            onChange={handleBoolChange}
                        />
                        <label className="btn-yes btn-question" htmlFor="incomeYes" aria-label="Yes"></label>
                    </div>
                    <div className="radio-btn-container">
                        <input 
                            checked={formData === false} 
                            type="radio" 
                            value="false" 
                            name="verifyIncome" 
                            id="incomeNo" 
                            onChange={handleBoolChange}
                        />
                        <label className="btn-no btn-question" htmlFor="incomeNo" aria-label="No"></label>
                    </div>
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default VerifyIncome;