import { useEffect } from "react";

/**
 * for using third party scripts such as universal lead id, Leadpoint's Google tag manager,
 * and Google Analytics
 */
export const useThirdPartyScripts = () => {
   return useEffect(() => {        
        function startLeadPointTagManager() {
            //@ts-ignore
            window.googleTagManager = (w, d, s, l, i) => {
                w[l] = w[l] || []; w[l].push({
                    'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
                }); var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            }            
            //@ts-ignore
            window.googleTagManager(window, document, 'script', 'dataLayer', 'GTM-KCMVZ6');        
        }
        startLeadPointTagManager()
    }, []);    
}