import LoanLead from "../../models/LoanLeads/LoanLead"
import ContinueButton from "./ContinueButton"
import PreviousButton from "./PreviousButton"

export type basicNavigationProps = {
    disabled?:boolean
    validationCheck?:() => boolean
    path:string
    handleClick?:() => void
    loanLead?:LoanLead
}

export type navigationButtonProps = {
    prevPath:string,
    nextPath:string,
    isFirstQuestion?:boolean
} & basicNavigationProps;

const NavigationButtons = (props:navigationButtonProps) => {
    const {disabled, validationCheck, handleClick, loanLead, prevPath, nextPath, isFirstQuestion} = props;

    //If css is messed up you removed: navigation-small
    return (
        <div className='form-actions'>
            {!isFirstQuestion && (
                <PreviousButton 
                    path={prevPath}
                    nextPath={nextPath}
                    loanLead={loanLead} 
                />
            )}
            <ContinueButton 
                disabled={disabled}
                path={nextPath} 
                prevPath={prevPath} 
                nextPath={nextPath}
                loanLead={loanLead}
                handleClick={handleClick}
                validationCheck={validationCheck}
            />
        </div> 
    )
}

export default NavigationButtons;