import React, { useEffect, useLayoutEffect } from 'react';
import '../../styles/fhaLoan/FhaLoanStyles.css'
type FhaLoanLayoutProps = {
    children:React.ReactNode
}

/**
 * Layout component that wraps over all questions to displaying FHA.com specific styles
 * @param props 
 * @returns 
 */
const FhaLoanLayout = (props:FhaLoanLayoutProps) => {
    const {children} = props;

    return (
        <>  
            <div className='fha-loan'>
                {children}
            </div>            
        </>
    )
}

export default FhaLoanLayout;