import { v4 as uuidv4 } from 'uuid';
import { CreditScoreEnum } from "../enums/CreditScoreEnum";
import { LeadBuyerEnum } from "../enums/LeadBuyerEnum";
import { LoanTypeEnum } from "../enums/LoanTypeEnum";
import { leadPointStates } from "../LeadPoint/LeadPointStates";
import { analyticsType, defaultAnalyticsFields } from "./analytics/analyticsType";
import { borrowerType, defaultBorrowerFields } from "./borrower/BorrowerType";
import { currentLoanType, defaultCurrentLoanFields } from "./currentLoan/CurrentLoanType";
import { defaultNewLoanField, newLoanType } from "./newLoan/NewLoanType";
import { defaultPropertyFields, propertyType } from "./property/PropertyType";
import { defaultThirdPartyRequiredFields, ThirdPartyRequiredFields } from "./thirdPartyRequiredFields/ThirdPartyRequiredFields";

export default class LoanLead {    

    /**
     * As of 2/28/2022 Paul and Bruce demanded that leads who loan values are less than 100K    
     *  */ 
    private _defaultLoanValue:number = 100000;


    private _state:string = "";

    /**
     * The Database ID that should automatically be generated when the lead is created
     */
    public id:string = uuidv4();

    /**
     * The type of loan the lead wants
     */
    public product:LoanTypeEnum = LoanTypeEnum.Purchase;
    
    /**
     * Fields that contain analytical information
     */
    public analytics:analyticsType = defaultAnalyticsFields;     

    /**
     * Fields that contain information about the lead
     */
    public borrower:borrowerType = defaultBorrowerFields;

    /**
     * Fields that contain information about the lead's current loan (refinance)
     */
    public currentLoan:currentLoanType = defaultCurrentLoanFields;
    
    /**
     * Fields that contain information about the new loan the lead is pursuing (purchase)
     */
    public newLoan:newLoanType = defaultNewLoanField;

    /**
     * Fields about the lead's property
     */
    public property:propertyType = defaultPropertyFields;

    /**
     * Property value of the home the lead is trying to purchase or refinance
     */
    public propertyValue:number = 250000

    /**
     * The percentage value of the down payment the lead is willing to accept
     * this value come from the down payment question
     */

    public percentDown:number = 15

    /**
     * fields required by third party organizations too ensure that leads are valid
     */
    public thirdPartyRequirements:ThirdPartyRequiredFields = defaultThirdPartyRequiredFields;

    /**
     * Get the calculated loan value
     */
    get LoanValue() {
        return this.calculateLoanValue(false);
    }

    /**
     * Calculated loan value that is used for the LTV AKA real loan value
     */
    get ltvLoanValue() {
        return this.calculateLoanValue(true);
    }

    /**
     * Down payment of the leads new loan
     */
    get downPayment() {
        return (this.propertyValue * this.percentDown/100.0);
    }

    /**
     *  Calculate which Lead buyer to send to
     */
    get leadBuyer() {      
          
        const lead = LoanLead.convertPartialLeadToLead(this);
        const state = lead.property.state;
        
        const foundLpState = leadPointStates.find(lpState => lpState === state);        

        //If the state is a LeadPoint state the lead buyer is LeadPoint
        if(foundLpState) 
            return LeadBuyerEnum.LeadPoint;

        //If the credit score is less than fair and the product is not refinance then the lead buyer is LeadPoint
        if(lead.borrower.creditScore < CreditScoreEnum.Fair && lead.product !== LoanTypeEnum.Refinance)
            return LeadBuyerEnum.LeadPoint;

        return LeadBuyerEnum.Peklava;        
    }

    /**
     * Calculates the loan value
     * @param isUsedForLTV if the loan value is going to be used to calculate LTV
     * @returns 
     */
    private calculateLoanValue = (isUsedForLTV:boolean):number =>  {
        const loanVal = this.product === LoanTypeEnum.Refinance ? this.currentLoan.mortgageBalance :  this.propertyValue - this.downPayment;

        if(isUsedForLTV)
            return loanVal

        //Bruce requested that all loan values be a min of 100k
        if(loanVal < this._defaultLoanValue)
        {
            return this._defaultLoanValue;
        } 
        
        return loanVal;        
    }

    /**
     * Get the value of the loan
     * @returns 
     */
    public getLoanValue = () => {
        return this.calculateLoanValue(false);
    }

    /**
     * Calculated loan value that is used for the LTV AKA real loan value
     */
    public getLtvLoanValue = () => {
        return this.calculateLoanValue(true);
    }

    public static getLeadBuyer = (lead:LoanLead) => {
        const state = lead.property.state;
        
        const foundLpState = leadPointStates.find(lpState => lpState === state);        

        //If the state is a LeadPoint state the lead buyer is LeadPoint
        if(foundLpState) 
            return LeadBuyerEnum.LeadPoint;

        //If the credit score is less than fair and the product is not refinance then the lead buyer is LeadPoint
        if(lead.borrower.creditScore < CreditScoreEnum.Fair && lead.product !== LoanTypeEnum.Refinance)
            return LeadBuyerEnum.LeadPoint;

        return LeadBuyerEnum.Peklava;   
    }

    /**
     *
     */
    constructor(lead?:LoanLead) {
        if(!lead)
            return;

        this.analytics = lead.analytics
        this.borrower = lead.borrower
        this.currentLoan = lead.currentLoan
        this.id = lead.id
        this.newLoan = lead.newLoan
        this.thirdPartyRequirements = lead.thirdPartyRequirements
        this.propertyValue = lead.propertyValue
        this.percentDown = lead.percentDown
        this.property = lead.property
        this.product = lead.product
        
    }

    /**
     * When converting from json our get, set, and functions are not converted over
     * so we need to convert the json obj and lead context obj to a loanLead obj     
     * @param jsonLead 
     */
    public static convertPartialLeadToLead = (lead:LoanLead) => {
        
        const newLead = new LoanLead();

        newLead.id = uuidv4();        
        newLead.product = lead.product
        newLead.analytics = lead.analytics
        newLead.borrower = lead.borrower
        newLead.currentLoan = lead.currentLoan
        newLead.newLoan = lead.newLoan
        newLead.property = lead.property
        newLead.propertyValue = lead.propertyValue
        newLead.percentDown = lead.percentDown
        newLead.thirdPartyRequirements = lead.thirdPartyRequirements

        return newLead;
    }

}

