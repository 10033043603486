import { useEffect, useState } from 'react';
import NavigationButtons from '../../../components/buttons/NavigationButtons';
import Typeahead from '../../../components/inputs/typeAhead/Typeahead';
import { GeoapifyController } from '../../../controllers/GeoapifyController';
import { DeepCopy } from '../../../helpers/DeepCopy';
import { StringHelper } from '../../../helpers/StringHelper';
import useOnQuestionMount from '../../../hooks/UseOnQuestionMount';
import { QuestionIdEnum } from '../../../models/enums/QuestionIdEnum';
import LoanLead from '../../../models/LoanLeads/LoanLead';
import { LoanLeadProps } from '../../../models/propTypes/LoanLeadProps';
import { GeoapifyAutocomplete } from '../../../models/responses/GeoapifyResponse';
import { stateDictionary } from '../../../models/stateDictionary/StateDictionary';
import '../../../styles/errors/ErrorStyles.css';
import '../../../styles/questions/PropertyLocation.css';

export type locationInput = {
    state:string
    city:string
    zip:string
}

type locationState = {
    location:locationInput
    showErrors:boolean    
    autoCompleteData:GeoapifyAutocomplete[]
    isLoading:boolean    
}

type LocationProps = {
    header:string
    isPropertyLocation:boolean
    previousQuestion:string
    nextQuestion:string    
    questionId:QuestionIdEnum
}

const geoapifyController = new GeoapifyController();

/**
 * This component was created because we need to have a property location and a lead location.
 * In order to not duplicate a lot of code this component was created to handle both
 * @returns 
 */
const Location = (props:LoanLeadProps & LocationProps) => {
    const {
            loanLead, 
            header,
            isPropertyLocation,
            updateAndStoreLead,
            previousQuestion,
            nextQuestion,            
            questionId,
    } = props;

    const limit = 20

    const autoCompleteType = "city"    

    const defaultState:locationState = {
        location: {
            state: isPropertyLocation ? loanLead.property.state : loanLead.borrower.basicInfo.BasicInfoState,
            city: isPropertyLocation ? loanLead.property.city : loanLead.borrower.basicInfo.BasicInfoCity,
            zip: isPropertyLocation ? loanLead.property.zip : loanLead.borrower.basicInfo.BasicInfoZip,
        },
        showErrors:false,        
        autoCompleteData:[],
        isLoading:false,
    }
    
    const [locState, setState] = useState(defaultState);    
    
    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    useEffect(() => {
        //When the property is updated we need to update our lead context
        function onPropertySet() {
            updateLead();
        }
        onPropertySet();
    }, [locState.location]);

        /**
     * Updates the lead and session storage
     */
    const updateLead = () => {
        let lead:LoanLead = DeepCopy.copy(loanLead);
        const {state, city, zip} = locState.location;
        const parsedCity = city.toLowerCase().trim();
        const parsedZip = zip.trim();
        const parsedState = stateDictionary.IsValidCode(state) ? state.trim().toUpperCase() : "";
        
        //Because if we are under refinance we still want to set the property info here.
        lead.property.city = parsedCity;
        lead.property.zip = parsedZip;
        lead.property.state = parsedState;

        if(!isPropertyLocation) {
            lead.borrower.basicInfo.BasicInfoCity = StringHelper.capitalizeMultiString(parsedCity);
            lead.borrower.basicInfo.BasicInfoZip = "";//We don't want the auto zip code because their are many zip codes per city
            lead.borrower.basicInfo.BasicInfoState = parsedState
        }
        
        updateAndStoreLead(lead);
    }

    /**
     * Takes in a search parameter and calls the geoapify controller to look for city data
     * @param searchText 
     */
    const queryForCities = async (searchText:string) => {
        setState(prevState => ({
            ...prevState,
            isLoading:true
        }));

        const autoCompleteData = await geoapifyController.autocompleteSearch(searchText, limit, autoCompleteType);        

        setState(prevState => ({
            ...prevState,
            autoCompleteData,
            isLoading:false
        }));
    }

    /**
     * Resets/clear out the autoComplete data
     */
    const resetAutoCompleteData = () => {
        setState(prevState => ({
            ...prevState,
            autoCompleteData: []
        }));
    }

    /**
     * When a city is selected we update our state
     * @param autoCompleteObj 
     */
    const handleOnSelect = (autoCompleteObj:GeoapifyAutocomplete) => {        
        // Check to see if we get a postCode bc Geoapify sometimes give cities with out a zip code
        //TODO get a state zip code from an API
        const zip = autoCompleteObj.postCode ?? stateDictionary.GetValidZip(autoCompleteObj.stateCode);

        setState(prevState => ({
            ...prevState,
            location: {
                state: autoCompleteObj.stateCode,
                city: autoCompleteObj.city,
                zip: zip
            },            
        }));
    }

    /**
     * Checks to see if we have a property location
     * @returns 
     */
    const onContinueValidation = () => {
        const {state, city} = locState.location
        if(
            StringHelper.isNullOrWhiteSpace(state) ||
            StringHelper.isNullOrWhiteSpace(city)            
        ) {
            setState(prevState => ({
                ...prevState,
                showErrors:true
            }));

            return true
        }

        return false
    }

    /**
     * Clears the property state
     */
    const clearProperty = () => {
        setState(prevState => ({
            ...prevState,
            location: {
                state: "",
                city: "",
                zip: ""
            }
        }))
    }

    return (
        <>
            <div className="question-content-container">
                <div className="question-header-container">
                    <h3 className="zip-header question-header">{header}</h3>
                </div>
                <div className="form-element zip-form-element">
                    <div className="zip-container">
                        <div className="zip">
                            <Typeahead 
                                data={locState.autoCompleteData} 
                                debounce={10} 
                                placeholder={'Search for City'}                                                         
                                minimumLength={4}
                                onChangeFunc={queryForCities} 
                                resetData={resetAutoCompleteData}
                                onSelect={handleOnSelect}
                                selectedInput={locState.location}
                                onInputClear={clearProperty}
                                isLoading={locState.isLoading}
                            />
                        </div>
                        <div className="error-container">
                            {
                                locState.showErrors && (
                                    /**
                                     * Add error handler
                                     */
                                    <span className="error-message">
                                        Please enter a VALID US city to continue
                                    </span>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}        
                validationCheck={onContinueValidation}       
            />
        </>
    )

}

export default Location;
