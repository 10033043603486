import React, { useEffect } from 'react';
import '../../styles/fha/FhaStyles.css';

type FhaLayoutProps = {
    children:React.ReactNode
}

/**
 * Layout component that wraps over all questions to displaying FHA.com specific styles
 * @param props 
 * @returns 
 */
const FhaLayout = (props:FhaLayoutProps) => {
    const {children} = props;
    
    return (
        <>
   
            {children}
        </>
    )
}

export default FhaLayout;