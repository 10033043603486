import { useEffect, useMemo, useRef } from 'react';
import { TexcorpController } from '../../controllers/TexcorpController';
import { DeepCopy } from '../../helpers/DeepCopy';
import { UrlHelper } from '../../helpers/UrlHelper';
import { GetCidFromUrlParams } from '../../models/enums/ClientTypeEnum';
import { QuestionIdEnum } from '../../models/enums/QuestionIdEnum';
import { ppcidDictionary } from '../../models/LoanLeads/analytics/PpcidDictionary';
import LoanLead from '../../models/LoanLeads/LoanLead';
import { PathItem } from '../../models/PathItems/PathItem';
import { LoanLeadProps } from '../../models/propTypes/LoanLeadProps';
import '../../styles/pages/ThankYou.css';

const texcorpController = new TexcorpController();

const ThankYou = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.ThankYou;

    const firstRender = useRef(false);

    /**
     * Credit score url
     */
    const creditScoreUrl = "https://www.cvrtrkpro.com/cmp/42332H/LFH7H/?source_id=FHALOAN-app";         

    /**
     * A tracking pixel url that is use for Bright Horizons Media analytics
     */
    const tackingPixelUrl = "https://www.cvrtrkpro.com/i/6J3CSR/2MG6QKW/?sub1=TYP";            

    const leadCopy = useMemo(() => {
        let lead:LoanLead = DeepCopy.copy(loanLead);        
        const s = window.history.state['submitForm'];        
        lead.analytics.completedForm = true;
        const cid = GetCidFromUrlParams();
        lead.analytics.cid = cid;
        lead.analytics.ppcid = ppcidDictionary[cid].toString();
        lead = PathItem.addPathItemToWorkflow(lead, questionId);

        return lead;
        
    }, []);    

    useEffect(() => {
        
        async function onComponentMount() {
            
            updateAndStoreLead(leadCopy);            
            
            if(firstRender.current === false) {
                
                await submitLeadToBuyer(leadCopy);
            }

            const completedForm = true;               
            
            await texcorpController.updatedCompletedForm(leadCopy, completedForm);
        }
        
        onComponentMount();        

        return () => {
            firstRender.current = true;
        } 
    },[]);

    const submitLeadToBuyer = async (lead:LoanLead) => {
        const submittedForm = window.history.state["submitForm"];
        if(submittedForm) {
            await texcorpController.submitLeadToLeadBuyer(lead);
            window.history.replaceState({}, '', '')
        }
    }

    /**
     * the accepted formatted version of our credit score url that Bhmedia accepts 
     * Contains lead's basic information so the text fields in the link should be filled out
     */
    const creditScoreUrlWithParams = useMemo(() => {               
        
        let lead = loanLead ?? new LoanLead();

        return UrlHelper.addParamsForCreditScore(lead, creditScoreUrl)
    },[loanLead]);

    /**
     * When the credit score button is clicked we patch the lead in the database
     */
    const onCreditScoreClick = async (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {        

        const lead:LoanLead = DeepCopy.copy(loanLead);        

        lead.analytics.clickedCreditScore = true;
        
        const clicked = true;

        await texcorpController.updateClickedCreditScore(lead, clicked);

        updateAndStoreLead(lead);
    }

    return (
        <div className="thankyou-container">
                        
            <div className="thankyou-inner-container">
                <p>Thank you for submitting an online inquiry about obtaining an FHA&nbsp;Home&nbsp;Loan.</p>

                <h2 className="text-important">Important Notice</h2>

                <p>Home prices are at an all-time high, and the market is very competitive. Improving your Credit Scores could <i>save&nbsp;you&nbsp;money</i> by getting you better mortgage terms.</p>            

                <p className="text-notice">
                    <em>It has never been more important to monitor and improve your Credit Scores!*</em>
                </p>

                <div className="btn-credscore">
                    <a 
                        href={creditScoreUrlWithParams}                        
                        target="_blank" 
                        className="credscore-link" 
                        onClick={onCreditScoreClick}
                    >                    
                        <span className="text-link-large">
                            Get Easy Access To
                        </span>
                        <span className="text-link-large">
                            Your Credit Scores
                        </span>

                        <span className="text-link-small">
                            Get Your Credit Scores
                        </span>
                    </a>                    
                </div>

                <img src={tackingPixelUrl} width="1px" height="1px" />
            
                <p className="text-notice">
                    Please be prepared for the NEXT STEP and obtain a copy of your newest Credit Report and Scores now.
                </p>

                <hr className="dashed" />

                <p>
                    Your Mortgage Specialist may ask for updated Credit Score when they contact you. It is important to check your scores and verify that your information is accurate so you can get the best rates.
                </p>

                <p>
                    Your scores determine if you loan will be approved and what interest rate you will receive. <span className="red-text"><em>*Using our service to obtain your Credit Scores does NOT affect your scores in any way.</em></span>
                </p>
            </div>
        </div>
    )
}

export default ThankYou;