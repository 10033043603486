import { HomePurchaseTimeEnum } from "../../enums/HomePurchaseTimeEnum";
import { PropertyTypeEnum } from "../../enums/PropertyTypeEnum";

export type propertyType = {
    
    homePurchaseTime:HomePurchaseTimeEnum
    
    propertyDescription:PropertyTypeEnum    
    
    city:string
    
    zip:string

    /**
     * The state where the property is located, checks if the value is valid and formats the value 
     */
    state:string
}

export const defaultPropertyFields:propertyType = {
    homePurchaseTime: HomePurchaseTimeEnum.days60,
    propertyDescription: PropertyTypeEnum.Single,    
    city: "",
    zip: "",
    state:""
}