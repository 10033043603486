import { StringHelper } from "../../helpers/StringHelper"
import { MortgageIncrements } from "../enums/MortgageBalanceIncrements"

export type MortgageBalanceType = {
    balance:number
    balanceText:string
    index:number
}

export class MortgageBalanceRange
{
    /**
     * The minimum value a mortgage balance can be
     */
    public min:number = 0

    /**
     * The value that represents the lead's mortgage balance
     * Calculated by using the max and mid values
     */
    public get mid() {
        return (this.min + this.max) / 2;
    }

    /**
     * The maximuim value the mortgage balance can be
     */
    public max:number = 0

    /**
     * Where the max and min are set
     */
    constructor(max:number, min:number) {
        this.max = max;
        this.min = min;
    }

    /// <summary>
    /// Constructor t
    /// </summary>
    public MortgageBalanceRange()
    {

    }
}

type mortgageBalanceDisplay = {
    [key:number]:string
}

export const lowestDefaultMortgage = 50000;

export class MortgageBalanceFormatter implements MortgageBalanceType {
    private _mortgageList:mortgageBalanceDisplay[] = [];
    public balance: number = 0
    public balanceText: string = ""
    public index: number = 0
    public mortgageRange = new MortgageBalanceRange(0, 0);

    /**
     *
     */
    constructor(range:MortgageBalanceRange) {
        this.mortgageRange = range;        
    }
    
    /**
     * 
     */
    get mortgageRangeList()  {
        const range = this.mortgageRange
        // Creating a new list with an initial value; Right now the string is just a placeholder
        this._mortgageList = [];

        this._mortgageList.push({[lowestDefaultMortgage]: "$47,501 - $52,500"});

        let incrementBy = this.getIncrementAmount(range.min);

        let lowestMortgage = lowestDefaultMortgage;

        while (lowestMortgage < range.max) {
            const maxRangeVal = range.min + incrementBy;

            let minRangeVal = maxRangeVal - MortgageIncrements.lowIncrement;

            const newRange = new MortgageBalanceRange(maxRangeVal, minRangeVal);
            
            // The +1 is because I want the mortgage displayed in ranges and I dont want a prev range as the minimum value for a new range
            const balanceDisplay = MortgageBalanceFormatter.formatMortgageBalanceRange(newRange.mid + 1, newRange.mid + MortgageIncrements.lowIncrement);

            this._mortgageList.push({
                [newRange.max]: balanceDisplay,                
            });

            lowestMortgage += this.getIncrementAmount(newRange.max);

            incrementBy += this.getIncrementAmount(newRange.max);
        }

        return this._mortgageList;
    }

    /**
     * lower and middle value come from the php fha loan form
     * @param balance 
     */
    private getIncrementAmount = (balance:number) => {
         
        // Value used to determine when to use the lower increment 
        const lowerValue = 197500;

        const middleValue = 395000;

        if(balance < lowerValue) {
            return MortgageIncrements.lowIncrement;
        }

        if(balance < middleValue) {
            return MortgageIncrements.midIncrement;
        }

        return MortgageIncrements.upperIncrement;
    }

    /**
     * Formats a mortgage balance range for displaying in the UI 
     */
    public static formatMortgageBalanceRange = (min:number, max:number) => {
        const formattedRange = `${StringHelper.formatNumberToDollars(min.toString())} - ${StringHelper.formatNumberToDollars(max.toString())}`;
        return formattedRange
    }
}
