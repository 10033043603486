import { ClientTypeEnum } from "../../enums/ClientTypeEnum";
import { LeadStatus } from "../../enums/LeadStatus";
import { QuestionIdEnum } from "../../enums/QuestionIdEnum";
import { defaultPathItem, PathItem } from "../../PathItems/PathItem";

export type baseAnalyticsType = {
    /**
     * The path the user took through the form
     */
     workflow:PathItem[]

     /**
      * If the lead have clicked the credit score button or not
      * on the thank you page
      */
     clickedCreditScore:boolean

    /**
     * If the user have completed the form by making it to the thank you page
     */ 
    completedForm:boolean

    /**
     * The last page the user left off on.
     * For the analytics dashboard (may not be in use).
     */
    LastPageVisited:QuestionIdEnum | null

     /**
      * The website the lead came from such as 
      * FHA, FHALoan, FHANEWSBLOG, etc
      */
     cid:ClientTypeEnum
    
     /**
      * Pretty much the same thing as cid but formatted differently.
      * it identifies what website the lead came from using a string of numbers.
      * I do not know why Peklava needs this when they already get the cid
      */
     ppcid:string
}

/**
 * Type that holds analytical information about the lead.
 * keep in mind some parts of this data is used by BP 
 * and others by both Peklava and LeadPoint
 */
export type analyticsType = {
    /**
     * The time the lead entered the form
     */
    captureTime:string 
    
    /**
     * The IP address of the lead
     */
    IPAddress:string

    /**
     * The agent the user was using when accessing the form
     * AKA browser info, etc
     */
    UserAgent:string

    /**
     * May not need if using routes
     */
    currentStep:number

    /**
     * The status of the lead after it has been submitted to Peklava or LeadPoint
     */
    leadStatus:LeadStatus
        
} & baseAnalyticsType;

export const defaultAnalyticsFields:analyticsType = {
    captureTime: new Date().toUTCString(),
    IPAddress: "",
    workflow: [],
    clickedCreditScore: false,
    UserAgent: window.navigator.userAgent,
    currentStep: 0,
    LastPageVisited: null,
    cid: ClientTypeEnum.Unknown,
    leadStatus: LeadStatus.Unknown,
    ppcid: "999",
    completedForm: false
}