export class EnumHelper {
    /**
     * Check to see if the passed in value is with the enum range
     */
    static checkIfWithinEnum = (value:number, enumObj:any) => {
        const enumLength = (Object.keys(enumObj).length/2) - 1

        return value <= enumLength;
    }
}
