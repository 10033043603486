import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { GeoapifyResponse } from "../models/responses/GeoapifyResponse";

export type GeoapifyAutoCompleteOptions = {
    searchText:string
    limit:number
    type:string
}
 /**
  * Geoapify services
  */
export class GeoapifyService {
    private baseUrl = "https://api.geoapify.com/v1/geocode";

    private geoAPiKey = process.env.REACT_APP_GEOAPIFY_KEY;

    private axiosConfiguration:AxiosRequestConfig = {};

    /**
     * Sends a request to the autocomplete endpoint in the 
     * Geoapify API
     */
    public getCityAutoComplete = async (options:GeoapifyAutoCompleteOptions) => {
        let {searchText, limit} = options;        
        const requestUrl = 
        this.baseUrl + "/autocomplete?text=" + encodeURIComponent(searchText) + "&lang=en&limit=" + 
        limit + "&type=locality&format=json&apiKey=" + this.geoAPiKey;
        
        const resp:AxiosResponse<GeoapifyResponse> = await axios.get(requestUrl, this.axiosConfiguration);
        
        return resp.data;
    }

}


