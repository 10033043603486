import { BasicInfo } from "../LoanLeads/basicInfo/BasicInfo"

export type basicInfoType = {
    firstName:string
    lastName:string
    address:string
    city:string
    zip:string
    state:string
    phone:string
    email:string
}

export const basicInfoFields:BasicInfo = {
    FirstName: "FirstName",
    LastName: "LastName",
    Email: "Email",
    Phone: "Phone",
    Address: "Address",
    BasicInfoState: "BasicInfoState",
    BasicInfoCity: "BasicInfoCity",
    BasicInfoZip: "BasicInfoZip"
}