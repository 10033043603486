import React, { useMemo, useState } from "react"
import { v4 as uuidv4 } from 'uuid'
import NavigationButtons from "../../../components/buttons/NavigationButtons"
import Slider, { sliderTrackClass } from "../../../components/sliders/Slider"
import { DeepCopy } from "../../../helpers/DeepCopy"
import { StringHelper } from "../../../helpers/StringHelper"
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount"
import { defaultDownPayScaleIndex, DownPayScale } from "../../../models/downPayments/DownpayScale"
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum"
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute"
import LoanLead from "../../../models/LoanLeads/LoanLead"
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps"

const DownPayment = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;
    const questionId = QuestionIdEnum.DownPayment;

    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.PropertyValue];
    
    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.MinDownPayment];

    const sliderMaxIndex = useMemo(() => (DownPayScale.length - 1),[]);    

    const defaultState = { 
        selectedDownPercent:loanLead.percentDown ? loanLead.percentDown : DownPayScale[defaultDownPayScaleIndex]
    }

    const [state, setState] = useState(defaultState);

    const leadCopy = useMemo(() => {
        const lead:LoanLead = DeepCopy.copy(loanLead);

        lead.percentDown = state.selectedDownPercent;
        
        return lead;
    },[]);

    useOnQuestionMount(loanLead, questionId,updateAndStoreLead);

    /**
     * Updates the local and context state
     * @param percent 
     */
    const updateState = (percent:number) => {
        
        setState(prevState => ({
            ...prevState,
            selectedDownPercent:percent
        }));

        const lead:LoanLead = DeepCopy.copy(loanLead);

        lead.percentDown = percent;

        updateAndStoreLead(lead);
    }

    /**
     * Handles the input we get back from the slider
     * @param index 
     */
    const handleSliderChange = (index:number) => {
        if(isNaN(index) || index > DownPayScale.length - 1)
            return;
                    
        const downPay = DownPayScale[index];
        
        updateState(downPay);
    }


    /**
     * Handles when the select input changes
     */
    const handleOnSelectChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        const inputValue = e.currentTarget.value;

        const percentDown = parseFloat(inputValue);

        if(isNaN(percentDown))
            return;
        
        updateState(percentDown);
    }

    /**
     * Get the slider index based on our current state
     * @returns 
     */
    const getSliderIndex = () => {
        const {selectedDownPercent} = state;

        const index = DownPayScale.findIndex(val => val === selectedDownPercent);

        return index;
    }

    /**
     * Calculates the down payment display in the ui
     * @param downPay 
     * @returns 
     */
    const displayDownPayText = (downPay?:number, forSlider?:boolean) => {
        const {selectedDownPercent} = state;
        
        const {propertyValue} = loanLead;

        const downPayAmount = propertyValue * ((downPay ?? selectedDownPercent)/100);

        const downPayText = StringHelper.formatNumberToDollars(downPayAmount.toFixed(0).toString());

        if(forSlider)
            return `(${downPay})% ${downPayText}`
        
        return `${downPay}% ${downPayText}`

    }

    /**
     * Display down payment current currency for the slider
     * @param index 
     * @returns 
     */
    const displayDownPaySlider = (index:number) => {
        const downPay = DownPayScale[index];

        return displayDownPayText(downPay, true);
    }

    return (
        <>
            <div className="question-header-container">
                <h3 className="question-header-small">Down payment?</h3>
                <h3 className="question-header-medium">What's Your Down Payment?</h3>
                <h3 className="question-header">How Much Is Your Down Payment?</h3>
                <span className="text-center tip">(It's OK to estimate.)</span>
            </div>
            <div className="form-element select-mobile-container">
                <select 
                    className="input-select-mobile input-border" 
                    value={state.selectedDownPercent}                     
                    onChange={handleOnSelectChange}
                >
                    {
                        DownPayScale.map((downPay) => (
                            <option 
                                key={uuidv4()}
                                value={downPay}
                            >
                                {displayDownPayText(downPay)}
                            </option>
                        ))
                    }
                </select>

                <Slider 
                    sliderTrack={sliderTrackClass.default}
                    showProgress={true}
                    min={0}
                    max={sliderMaxIndex}
                    defaultValue={getSliderIndex()}
                    setValue={handleSliderChange}
                    displayInSlider={displayDownPaySlider}
                />

                <span className="downpayment-text">
                    Note: This is not an inquiry for
                    down payment assistance programs. FHA minimum down payment is 3.5%
                </span>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default DownPayment