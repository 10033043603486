import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { ClientTypeEnum, StringToClientTypeEnum } from "../../models/enums/ClientTypeEnum";
import FhaLoanLayout from "./FhaLoanLayout";
import FhaLayout from "./FhaLayout";

type MainLayoutProps = {
    children:React.ReactNode
}

/**
 * Main Layout of the entire form
 * Here you can set different layouts such as fha, or fha loan
 * @param props 
 * @returns 
 */
const MainLayout = (props:MainLayoutProps) => {
    const {children} = props;

    useEffect(() => {
        
    }, []);

    //Gets the CID from the url query string and decide if we should display FHA or FHA Loan layout/styles
    const layoutToDisplay = useMemo(() => {
        const queryParams = new URLSearchParams(window.location.search);

        const cid = queryParams.get("cid") || queryParams.get("CID");        

        const convertedCID = StringToClientTypeEnum(cid);
        
        if(convertedCID === ClientTypeEnum.FHALOAN)
            return ClientTypeEnum.FHALOAN;
        
        return ClientTypeEnum.FHA;
    
    },[])

    return (
        <>
            {
                layoutToDisplay === ClientTypeEnum.FHALOAN ? 
                (                    
                    <FhaLoanLayout>                        
                        {children}
                    </FhaLoanLayout>
                ) 
                : 
                (

                    <FhaLayout>                    
                        {children}
                    </FhaLayout>

                )
            }
        </>
    )
}

export default MainLayout;