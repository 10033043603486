import {z} from "zod";

const baseErrorMsg = "Please Enter Your ";

const phoneNumberRegex = RegExp("/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im")

const alphanumericRegex = RegExp("^[a-zA-Z0-9 ]*$")

const alphanumericMsg = "Please only include alphanumeric characters";

export class BasicInfoSchema {
    static firstNameSchema = z.string().trim().min(1, {message: baseErrorMsg + "First Name"}).regex(alphanumericRegex, {message: alphanumericMsg});
    
    static lastNameSchema = z.string().trim().min(1, {message: baseErrorMsg + "Last Name"}).regex(alphanumericRegex, {message: alphanumericMsg});
    
    static addressSchema = z.string().trim().min(1, {message: baseErrorMsg + "Address"}).regex(alphanumericRegex, {message: alphanumericMsg});
    
    static citySchema = z.string().min(1, {message: baseErrorMsg + "City"}).regex(alphanumericRegex, {message: alphanumericMsg});
    
    static stateSchema = z.string().min(1, {message: baseErrorMsg + "State"});
    
    static zipSchema =  z.string()
                                .min(1, {message: "Zip code cannot be empty"})
                                .min(5, {message: "Zip code must be 5 numeric digits in length"})
                                .max(5, {message: "Zip code must be 5 numeric digits in length"})                                                              
    
    static phoneSchema = z.string()
                                .min(1, {message: baseErrorMsg + "10 digit Phone Number"}) 
                                .min(10, {message: "Please Enter a valid 10 digit Phone Number"}) 
                                .max(10, {message: "Please enter a valid 10 digit Phone Number"}) 

    
    static emailSchema = z.string().min(1, {message: baseErrorMsg + "Email"}).email({message: "Please enter a valid email address"});

    static allFieldsSchema = z.object({
        FirstName: this.firstNameSchema,
        LastName: this.lastNameSchema,
        Address: this.addressSchema,
        BasicInfoCity: this.citySchema,
        BasicInfoState: this.stateSchema,
        BasicInfoZip: this.zipSchema,
        Phone: this.phoneSchema,
        Email: this.emailSchema,
    });
}


export const firstName = z.string().min(1, {message: baseErrorMsg + "First Name"});