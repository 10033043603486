import '../../styles/pages/PolicyAndTermsStyles.css';

const TermsOfService = () => {

    return (
        <div className="privacy-page-container">
            <p><strong>FHALoan.com Terms of Service</strong></p>
            <p>Updated March 28, 2019</p>

            <ol>
                <li className="padding-bottom-20">
                    INTRODUCTION<br />
                    Thank you for visiting FHALoan.com.  These Terms of Service are an integral part of using our Service, and you must completely agree to them in order to use our Service
                </li>


                <li className="padding-bottom-20">
                    CORPORATE INFORMATION<br />
                    FHALoan.com is owned and operated by First Click Mortgage, Inc., a corporation formed and doing business in the State of Texas under Secretary of State File Number 0134285400.  Unless otherwise required or permitted by this Agreement, our Privacy Policy, or any provisions of applicable law, any legal documents should be sent via certified mail to our Registered Agent, whose most current information can be found by searching for our corporation with the Texas Secretary of State.
                </li>

                <li className="padding-bottom-20">
                    DEFINITIONS<br />
                    Throughout this Agreement, we may use certain words or phrases, and it is important that you understand the meaning of them.  The list is not all-encompassing and no definition should be considered binding to the point that it renders this Agreement nonsensical:<br /><br />

                    <ul className="space1">
                        <li>"Agreement" means these Terms of Service;</li>
                        <li>"FHALoan.com" refers to our company, known as "First Click Mortgage, Inc."; our Site; our Service; or a combination of all or some of the preceding definitions, depending on the context of the word;</li>
                        <li>"Service" refers to the services that we provide through our Site, including the information provided through our Site and our Site itself;</li>
                        <li>"Site" refers to our website, www.FHALoan.com;</li>
                        <li>"User" refers to anyone who visits our Site;</li>
                        <li>"You" refers to you, the person who is entering into this Agreement with FHALoan.com.</li>
                    </ul>
                </li>

                <li className="padding-bottom-20">
                    THE SCOPE OF OUR SERVICE<br />
                    First Click Mortgage, Inc. operates the Site.  The websites operated by First Click Mortgage, Inc. provide news and information about mortgage products generally.  First Click Mortgage, Inc., does not offer or sell mortgage credit products.  Advertisers on First Click Mortgage, Inc.'s websites may generate leads and referrals for mortgage credit products through their advertisements.<br /><br />

                    Once a User reaches the Site, the User is able to review news and information provided by the site at no cost.  Users may also enter information regarding their mortgage needs and their contact information into a secure hosted advertising form that transmits this information to one of several advertisers.  A User may also view advertisements that, if clicked, connect the User to the advertiser's website.  In addition, advertisers may also utilize a telephone number displayed on the site that will connect a User directly to the advertiser.
                </li>

                <li className="padding-bottom-20">
                    ELIGIBILITY<br />
                    In order to use our Service, you must meet a number of conditions, including but not limited to:<br /><br />

                    <ul className="space1">
                        <li>You must not be in violation of any embargoes, export controls, or other laws of the United States or other countries having jurisdiction over this Agreement, FHALoan.com, and yourself.  For example, if the Office of Foreign Assets Control prohibits conducting financial transactions with nationals, residents, or banks of your country, you must not use our Service.</li>
                        <li>You must be the minimum age required to enter into a contract in the area in which you reside, and, in any event, must not be less than 18 years of age.</li>
                        <li>You must not use our Service to facilitate mortgage applications which involve fraud or other misrepresentations, or fail to make required disclosures in order to obtain credit.</li>
                    </ul>
                </li>

                <li className="padding-bottom-20">
                    NATURE OF SERVICE<br />
                    FHALoan.com acts online as an information provider and medium for advertisers.  We make no warranties or any other representations as to the reliability or honesty of any advertiser, nor do we recommend relying on any content that we post as the basis of any financial decisions.  You agree that you are solely responsible for your own financial decisions, and release FHALoan.com from any liability whatsoever regarding our Service or any causes of action arising from or relating to our Service.
                </li>

                <li className="padding-bottom-20">
                    RULES OF USE<br />
                    In addition to complying with any other rules in this Agreement, you must not:<br /><br />

                    <ul className="">
                        <li>Violate the laws of the United States, its states, or any foreign political entity having jurisdiction over this Agreement, whether or not the foreign political entity is a country or a subdivision (such as a state or province) or municipality (such as a city, town, county, or region) of a foreign country.</li>
                        <li>Post or send anything violent, threatening, pornographic, racist, hateful, or otherwise objectionable according to the opinion of FHALoan.com or its delegates (including submitting such content to advertisers).</li>
                        <li>Be fraudulent or negligent in your representations when making credit applications with third parties.</li>
                        <li>Infringe on anyone's intellectual property rights, defame anyone, impersonate anyone, or otherwise violate the rights of a third party.</li>
                        <li>Hack, crack, phish, SQL inject, or otherwise compromise the security or integrity of the FHALoan.com Site, Service, or its Users' computers.</li>
                        <li>Do anything else which, at our sole discretion, is objectionable, or which a reasonable person would conclude is or will constitute a violation of any person's rights.</li>
                    </ul>
                </li>

                <li className="padding-bottom-20">
                    OUR COPYRIGHT<br />
                    FHALoan.com is a largely content-based service.  You agree not to copy, distribute, display, disseminate, or otherwise reproduce any of the information on the Site, including advertisements submitted by third parties to FHALoan.com, without receiving our prior written permission.
                </li>
                <li className="padding-bottom-20">
                    YOUR COPYRIGHT<br />
                    FHALoan.com must be assured that it has the right to use the content that is posted to its Site by its Users.  Such content may include, but is not limited to, information submitted to us through our online forms.  Whenever submitting content to our Service, you agree that you are granting us a non-exclusive, universal, perpetual, irrevocable, sublicensable, commercial and non-commercial right to use, distribute, sell, publish, and otherwise make use of the content that you submit to us.  You warrant to us that you have the right to grant us this right over the content, and that you will indemnify us for any loss resulting from a breach of this warranty and defend us against claims regarding the same.
                </li>

                <li className="padding-bottom-20">
                    TRADEMARKS<br />
                    "FHALoan.com" and "First Click Mortgage, Inc." are trademarks used by us, First Click Mortgage, Inc., to uniquely identify our Site, Service, and business.  You agree not to use this phrase anywhere without our prior written consent.  Additionally, you agree not to use our trade dress, or copy the look and feel of our website or its design, without our prior written consent.  You agree that this paragraph goes beyond the governing law on intellectual property law, and includes prohibitions on any competition that violates the provisions of this paragraph, including starting your own mortgage-related service.
                </li>

                <li className="padding-bottom-20">
                    REVOCATION OF CONSENT<br />
                    We may revoke our consent for your use of our intellectual property, or any other permission granted to you under this Agreement, at any time.  You agree that if we so request, you must take immediate action to remove any usage of our intellectual property that you may have engaged in, even if it would cause a loss to you.
                </li>

                <li className="padding-bottom-20">
                    REPRESENTATIONS & WARRANTIES<br />
                    WE MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE MERCHANTABILITY OF OUR SERVICE OR FITNESS FOR ANY PARTICULAR PURPOSE.  YOU AGREE THAT YOU ARE RELEASING US FROM ANY LIABILITY THAT WE MAY OTHERWISE HAVE TO YOU IN RELATION TO OR ARISING FROM THIS AGREEMENT OR OUR SERVICES, FOR REASONS INCLUDING, BUT NOT LIMITED TO, FAILURE OF OUR SERVICE, NEGLIGENCE, OR ANY OTHER TORT.  TO THE EXTENT THAT APPLICABLE LAW RESTRICTS THIS RELEASE OF LIABILITY, YOU AGREE THAT WE ARE ONLY LIABLE TO YOU FOR THE MINIMUM AMOUNT OF DAMAGES THAT THE LAW RESTRICTS OUR LIABILITY TO, IF SUCH A MINIMUM EXISTS.<br /><br />

                    YOU AGREE THAT WE ARE NOT RESPONSIBLE IN ANY WAY FOR DAMAGES CAUSED BY THIRD PARTIES WHO MAY USE OUR SERVICES, INCLUDING BUT NOT LIMITED TO PEOPLE WHO COMMIT INTELLECTUAL PROPERTY INFRINGEMENT, DEFAMATION, TORTIOUS INTERFERENCE WITH ECONOMIC RELATIONS, OR ANY OTHER ACTIONABLE CONDUCT TOWARDS YOU.<br /><br />

                    WE ARE NOT RESPONSIBLE FOR ANY FAILURE ON THE PART OF A THIRD PARTY (INCLUDED BUT NOT LIMITED TO MORTGAGE ADVERTISERS) TO DELIVER GOODS OR SERVICES AS PROMISED OR EXPECTED.  YOU AGREE TO ASSUME ANY RISK INVOLVED IN DEALING WITH ADVERTISERS OR THE GOODS OR SERVICES THAT THEY PROMOTE.<br /><br />

                    WE ARE NOT LIABLE FOR ANY FAILURE OF THE GOODS OR SERVICES OF OUR COMPANY OR A THIRD PARTY, INCLUDING ANY FAILURES OR DISRUPTIONS, UNTIMELY DELIVERY, SCHEDULED OR UNSCHEDULED, INTENTIONAL OR UNINTENTIONAL, ON OUR WEBSITE WHICH PREVENT ACCESS TO OUR WEBSITE TEMPORARILY OR PERMANENTLY.<br /><br />

                    THE PROVISION OF OUR SERVICE TO YOU IS CONTINGENT ON YOUR AGREEMENT WITH THIS AND ALL OTHER SECTIONS OF THIS AGREEMENT.  NOTHING IN THE PROVISIONS OF THIS "REPRESENTATIONS & WARRANTIES" SECTION SHALL BE CONSTRUED TO LIMIT THE GENERALITY OF THE FIRST PARAGRAPH OF THIS SECTION.<br /><br />

                    <div >
                        <em><strong>For Jurisdictions that do not allow us to limit our liability:</strong></em> Notwithstanding any provision of these Terms, if your jurisdiction has provisions specific to waiver or liability that conflict with the above then our liability is limited to the smallest extent possible by law.  Specifically, in those jurisdictions not allowed, we do not disclaim liability for: (a) death or personal injury caused by its negligence or that of any of its officers, employees or agents; or (b) fraudulent misrepresentation; or (c) any liability which it is not lawful to exclude either now or in the future.<br /><br />
                    </div>

                    IF YOU ARE A RESIDENT OF A JURISDICTION THAT REQUIRES A SPECIFIC STATEMENT REGARDING RELEASE THEN THE FOLLOWING APPLIES.  FOR EXAMPLE, CALIFORNIA RESIDENTS MUST, AS A CONDITION OF THIS AGREEMENT, WAIVE THE APPLICABILITY OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR."  YOU HEREBY WAIVE THIS SECTION OF THE CALIFORNIA CIVIL CODE.  YOU HEREBY WAIVE ANY SIMILAR PROVISION IN LAW, REGULATION, OR CODE THAT HAS THE SAME INTENT OR EFFECT AS THE AFOREMENTIONED RELEASE.
                </li>

                <li className="padding-bottom-20">
                    INDEMNITY<br />
                    You agree to indemnify and hold us harmless for any claims by you or any third party which may arise from or relate to this Agreement or the provision of our service to you, including any damages caused by your use of our Site or acceptance of the offers contained on it.  You also agree that you have a duty to defend us against such claims and we may require you to pay for an attorney(s) of our choice in such cases.  You agree that this indemnity extends to requiring you to pay for our reasonable attorneys' fees, court costs, and disbursements.  In the event of a claim such as one described in this paragraph, we may elect to settle with the party/parties making the claim, and you shall be liable for the damages as though we had proceeded with a trial.
                </li>

                <li className="padding-bottom-20">
                    CHOICE OF LAW<br />
                    This Agreement shall be governed by the laws in force in the State of Texas.  The offer and acceptance of this contract are deemed to have occurred in the State of Texas.
                </li>

                <li className="padding-bottom-20">
                    FORUM OF DISPUTE<br />
                    You agree that any dispute arising from or relating to this Agreement will be heard solely by a court of competent jurisdiction in the State of Texas.  Specifically, where the subject matter of a dispute is eligible for it, you agree that any disputes shall be heard solely within the lowest court of competent jurisdiction having the authority to hear civil matters ("Small Claims Court").<br /><br />

                    If a dispute claims multiple claims and one or more of those claims would be eligible to be heard by the Small Claims Court, you agree not to bring the other claims against us and to instead proceed within the Small Claims Court.<br /><br />

                    If you would be entitled in a dispute to an amount exceeding the monetary jurisdiction of the Small Claims Court, you agree to waive your right to collect any damages in excess of the monetary jurisdiction and instead still bring your claim within the Small Claims Court.<br /><br />

                    You agree that if a dispute is eligible to be heard in Small Claims Court but you would be entitled to an additional or alternative remedy in a higher court, such as injunctive relief, you will waive your right to that remedy and still bring the dispute within the Small Claims Court.<br /><br />

                    If you bring a dispute in a manner other than in accordance with this section, you agree that we may move to have it dismissed, and that you will be responsible for our reasonable attorneys' fees, court costs, and disbursements in doing so.<br /><br />

                    You agree that the unsuccessful party in any dispute arising from or relating to this Agreement will be responsible for the reimbursement of the successful party's reasonable attorneys' fees, court costs, and disbursements.
                </li>

                <li className="padding-bottom-20">
                    FORCE MAJEURE<br />
                    You agree that we are not responsible to you for anything that we may otherwise be responsible for, if it is the result of events beyond our control, including, but not limited to, acts of God, war, insurrection, riots, terrorism, crime, labor shortages (including lawful and unlawful strikes), embargoes, postal disruption, communication disruption, unavailability of payment processors, failure or shortage of infrastructure, shortage of materials, or any other event beyond our control.
                </li>

                <li className="padding-bottom-20">
                    SEVERABILITY<br />
                    In the event that a provision of this Agreement is found to be unlawful, conflicting with another provision of the Agreement, or otherwise unenforceable, the Agreement will remain in force as though it had been entered into without that unenforceable provision being included in it.<br /><br />

                    If two or more provisions of this Agreement are deemed to conflict with each other's operation, FHALoan.com shall have the sole right to elect which provision remains in force.
                </li>

                <li className="padding-bottom-20">
                    NON-WAIVER<br />
                    FHALoan.com reserves all rights afforded to us under this Agreement as well as under the provisions of any applicable law.  Our non-enforcement of any particular provision or provisions of this Agreement or any applicable law should not be construed as our waiver of the right to enforce that same provision under the same or different circumstances at any time in the future.
                </li>

                <li className="padding-bottom-20">
                    TERMINATION & CANCELLATION<br />
                    We may terminate your account or access as well as access to our Site and Service to you at our discretion without explanation, though we will strive to provide a timely explanation in most cases.  Our liability for refunding you, if you have paid anything to us, will be limited to the amount you paid for goods or services which have not yet been and will not be delivered, except in cases where the termination or cancellation was due to your breach of this Agreement, in which case you agree that we are not required to provide any refund or other compensation whatsoever.<br /><br />

                    Under no circumstances, including termination or cancellation of our Service to you, will we be liable for any losses related to actions of other Users.
                </li>

                <li className="padding-bottom-20">
                    ASSIGNMENT OF RIGHTS<br />
                    You may not assign your rights and/or obligations under this Agreement to any other party without our prior written consent.  We may assign our rights and/or obligations under this Agreement to any other party at our discretion.
                </li>

                <li className="padding-bottom-20">
                    AMENDMENTS<br />
                    We may amend this Agreement from time to time.  When we amend this Agreement, we will place a note on our Site as to when this Privacy Policy was last notified.  You must read this page each time you access our Service, and your continued use of our Service constitutes your agreement to any amendments.
                </li>

                <li className="padding-bottom-20">
                    CALIFORNIA USERS AND RESIDENTS<br />
                    Pursuant to California Civil Code Section 1789.3, any questions about pricing, complaints, or inquiries about FHALoan.com must be addressed to our agent for notice and sent via certified mail to our agent for service.  For the most up-to-date contact information for our agent of service, please contact assistance@FHALoan.com.<br /><br />

                    Lastly, California users are also entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
                </li>

            </ol>

            <p>Last Modified:  April 1, 2018</p>

        </div>
    )
}

export default TermsOfService;