import LoanLead from "../models/LoanLeads/LoanLead";

type creditScoreLinkParams = {
    sub1:string
    sub2:string
    firstName:string
    lastName:string
    address:string
    state:string
    city:string
    zipCode:string
    email:string
    phone:string    
}

export class UrlHelper {
    
    static test = () => {
        return "";
    }

    static addParamsForCreditScore = (loanLead:LoanLead, url:string | URL) => {        
        url = new URL(url);

        // Default for now since the lead do not have a ppcid
        const defaultSub1 = "103";

        // "Thank-You must be added for sub2 plus the cid"
        const defaultSub2 = "FHA-Thank-You";        

        const {borrower: {basicInfo: {FirstName, LastName, Address, BasicInfoState, BasicInfoCity, BasicInfoZip, Email, Phone}}} = loanLead;

        // const params = new URLSearchParams(credScoreParams);

       const params = new URLSearchParams(
            {                
                sub1: defaultSub1, 
                sub2: defaultSub2, 
                firstname: FirstName, 
                lastname: LastName,
                address: Address,
                city: BasicInfoCity, 
                zipcode: BasicInfoZip, 
                state: BasicInfoState, 
                email: Email, 
                phone: Phone
            }
        )

        const new_url =new URL(`${url.origin}${url.pathname}?source_id=FHALOAN-app&${params}`)

        // const encodedUrl = new URL(`${url.origin}${url.pathname}${url.search}&${params}`);

        return new_url.href;
    }
}