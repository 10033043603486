import { useEffect } from "react";
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useFields from "../../../hooks/UseFields";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

/**
 * Question for if the lead wants to be matched with a home agent salesperson
 * This question should only appear if they answered no found home
 * @param props 
 * @returns 
 */
const WantAgentMatch = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;
    
    const questionId = QuestionIdEnum.WantAgent;

    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.FoundHome];
    
    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.WhenBuyHome];
        
    const [formDate,,handleBoolChange] = useFields(loanLead.newLoan.WantAgentMatch);

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    useEffect(() => {
        function onFormDataChange() {
            const wantAgentMatch = formDate;

            const lead:LoanLead = DeepCopy.copy(loanLead)

            lead.newLoan.WantAgentMatch = wantAgentMatch;      
            
            updateAndStoreLead(lead);
        }
        onFormDataChange();
    },[formDate])

    return (
        <>
            <div  className="question-header-container">
                <h3 className="question-header">Want to be matched with a local real estate agent?</h3>
            </div>

            <div className="form-element">
                <div className="btn-two-container">
                    <div className="radio-btn-container">
                        <input 
                            checked={formDate === true} 
                            type="radio" 
                            value="true"
                            name="wantAgentMatch" 
                            id="wantAgentMatchYes" 
                            onChange={handleBoolChange} 
                        />
                        <label className="btn-yes btn-question" htmlFor="wantAgentMatchYes" aria-label="Yes"></label>
                    </div>

                    <div className="radio-btn-container">
                        <input 
                            checked={formDate === false} 
                            type="radio" 
                            value="false"
                            name="wantAgentMatch" 
                            id="wantAgentMatchNo" 
                            onChange={handleBoolChange} 
                        />
                        <label className="btn-no btn-question" htmlFor="wantAgentMatchNo" aria-label="No"></label>
                    </div>
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default WantAgentMatch;