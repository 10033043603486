export enum CreditScoreEnum {
    // Credit scores between 559 or less
    Poor = 0,
    // Credit scores betwee 560 to 599
    Fair = 1,
    // Credit scores between 600 to 639
    Average = 2,
    // Credit scores between 640 to 679
    Good = 3,
    // Credit scores of 680 or higher
    Excellent = 4
}

export const creditScoreDisplay = {
    [CreditScoreEnum.Poor]: "Poor",
    [CreditScoreEnum.Fair]: "Fair",
    [CreditScoreEnum.Average]: "Good",
    [CreditScoreEnum.Good]: "Very Good",
    [CreditScoreEnum.Excellent]: "Excellent"
}