import '../../styles/pages/PolicyAndTermsStyles.css';

const Contact = () => {
    return (
        <div className="privacy-page-container">
            <p><em>FHALoan.com is highly committed to providing excellent customer service.</em></p>
            <p><strong>NOTE: We are not the FHA.</strong> They insure the FHA loans that we assist people in getting. If you need to contact the FHA directly, you can do so at:</p>
            <p>
                US Department of Housing and Urban Development<br />
                451 7th Street, S.W.<br />
                Washington, DC 20410
            </p>
            <p>Should you have any questions, please e-mail us at:</p>
            <p><a href="mailto:assistance@fhaloan.com">assistance@fhaloan.com</a></p>

            <p>Lender looking to participate on our site? Contact us at:</p>
            <p><a href="mailto:assistance@fhaloan.com">assistance@fhaloan.com</a></p>

            <p><strong>OUR ADDRESS:</strong></p>
            <p>
                5850 San Felipe, Suite #500<br />
                Houston Texas 77057
            </p>
        </div>
    )
}

export default Contact;