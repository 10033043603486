const useHiddenFields = () => {
        /**
     * Because hidden fields are replaced we cannot use useRef and have to
     * get them the ole javaScript way
     */
    const getTrustedHiddenFields = () => {
        const trustedFormToken = document.querySelector('input[name="xxTrustedFormToken"]') as HTMLInputElement;
        const trustedCertUrl = document.querySelector('input[name="xxTrustedFormCertUrl"]') as HTMLInputElement;;       
        const trustedPingUrl = document.querySelector('input[name="xxTrustedFormPingUrl"]') as HTMLInputElement;;

        return [trustedFormToken.value, trustedCertUrl.value, trustedPingUrl.value]
        }

    return [getTrustedHiddenFields];
}

export default useHiddenFields;