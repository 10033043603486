const LeadPointStatement = () => {
    return (
        
        <div id="">
           <input type="hidden" id="leadid_tcpa_disclosure"/>
           <label htmlFor="leadid_tcpa_disclosure">
                By clicking the button above, you acknowledge, consent and agree to the following: a) Our {" "}
                <a href="http://www.securerights.org/privacy.html" target="_blank">Privacy Policy</a> and consent to receive notices and other {" "}
                <a href="http://www.securerights.org/electroniccommunications.html" target="_blank">communications electronically</a>; b) 
                We take your privacy seriously. You are providing express written consent for SecureRights to share your information with up to four (4) 
                of its <a href="http://www.securerights.org/networkmembers.html" target="_blank">Premier Partners</a> {" "}
                and for SecureRights, parties calling on behalf of Secure Rights, Premier Partners or authorized third parties on their behalf to contact you 
                (including through automated means; e.g. autodialing, text and pre-recorded messaging) about financial services or other credit related offers 
                via telephone, mobile device (including SMS and MMS) and/or email, even if your telephone number is currently listed on any state, federal, 
                local or corporate Do Not Call list; c) Consent to be contacted is not required in order to purchase goods or services from SecureRights or the 
                Premier Partners that contact you. You may choose to speak with an individual service provider by dialing (888) 883-2062; and d) 
                That I have received and reviewed the <a href="http://securerights.org/licenses.html" target="_blank"> Mortgage Broker Disclosures</a> {" "}
                for my state; and e) You are providing written consent under the Fair Credit Reporting Act for SecureRights to obtain information from your 
                personal credit profile. You authorize SecureRights to obtain such information solely to prequalify you 
                for credit options and connect you with an appropriate lending partner. Secure Rights NMLS 3175.
           </label>
        </div>
        
    )
}

export default LeadPointStatement