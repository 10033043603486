export type fieldToValueType = {
    field:string,
    value:any
}

export interface IPatchDocument {
    path:string
    op:string
    value:any
}

/**
 * Class for sending request to partially update/patch specified fields
 * path is the field you want to update example "/clickedCreditScore"
 * value is the value you want the field to be updated with 
 */
export class PatchJSONDocument implements IPatchDocument{
    path: string
    op: string = "replace"
    value: any
    
    constructor(path:string, value:any) {
        this.path = path;
        this.value = value;        
    }

}