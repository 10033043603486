import { useLayoutEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/layouts/Layout';
import ThankYouLayout from './components/layouts/ThankYouLayout';
import { StorageHelper } from './helpers/StorageHelper';
import { QuestionIdEnum } from './models/enums/QuestionIdEnum';
import { QuestionIdToRoute } from './models/enums/QuestionIdToRoute';
import LoanLead from './models/LoanLeads/LoanLead';
import Home from './pages/home/Home';
import Contact from './pages/policyAndTerms/Contact';
import Disclaimer from './pages/policyAndTerms/Disclaimer';
import PrivacyPolicy from './pages/policyAndTerms/PrivacyPolicy';
import TermsOfService from './pages/policyAndTerms/TermsOfService';
import Bankruptcy from './pages/questions/bothPurchaseRefi/Bankruptcy';
import BasicInformation from './pages/questions/bothPurchaseRefi/BasicInformation';
import CreditRating from './pages/questions/bothPurchaseRefi/CreditRating';
import LateMortgagePayments from './pages/questions/bothPurchaseRefi/LateMortgagePayments';
import MilitaryService from './pages/questions/bothPurchaseRefi/MilitaryService';
import PropertyDescription from './pages/questions/bothPurchaseRefi/PropertyDescription';
import PropertyLocation from './pages/questions/bothPurchaseRefi/PropertyLoaction';
import PropertyValue from './pages/questions/bothPurchaseRefi/PropertyValue';
import VerifyIncome from './pages/questions/bothPurchaseRefi/VerifyIncome';
import WhatTypeOfLoan from './pages/questions/bothPurchaseRefi/WhatTypeOfLoan';
import DownPayment from './pages/questions/purchase/DownPayment';
import FoundHome from './pages/questions/purchase/FoundHome';
import MinDownPayment from './pages/questions/purchase/MinDownPayment';
import WantAgentMatch from './pages/questions/purchase/WantAgentMatch';
import WhenBuyHome from './pages/questions/purchase/WhenBuyHome';
import BorrowCash from './pages/questions/refinance/BorrowCash';
import CurrentInterestRate from './pages/questions/refinance/CurrentInterestRate';
import ExistingLoan from './pages/questions/refinance/ExistingLoan';
import LeadLocation from './pages/questions/refinance/LeadLocation';
import MortgageBalance from './pages/questions/refinance/MortgageBalance';
import ThankYou from './pages/thankYou/ThankYou';

function App() {
	const [leadState, leadSetState] = useState(StorageHelper.retrieveLead());	  

  useLayoutEffect(() => {
    //prevents scroll from starting a the bottom of the screen
    window.history.scrollRestoration = 'manual';
  },[]);

  /**
   * Updates and stores the lead
   * @param lead 
   */
	const updateLead = async (lead:LoanLead) => {        
		let newLead = new LoanLead(lead);		

        leadSetState(newLead);

        StorageHelper.storeLead(newLead);		            
	}
  
  /**
   * Get the current question the lead is on and return the 
   * route for that question
   */
  const getCurrentQuestionRoute = () => {        
    const length = leadState.analytics.workflow.length - 1;

    if(length < 0)
      return getPath(QuestionIdEnum.LoanType);
    
    const lastPathItem = leadState.analytics.workflow[length];

    return getPath(lastPathItem.componentID);
  }

  /**
  * Returns a route when passed in a question id
  * @param id 
  * @returns 
  */
  const getPath = (id:QuestionIdEnum) => {
    return QuestionIdToRoute[id];
  }

  return (                   
        <div className="App">                              
              <Routes>
                {/* Question Routes */}
                <Route element={<Layout/>}>              
                  <Route path='/' element={<Home loanLead={leadState} updateAndStoreLead={updateLead}/>}/>
                  <Route path={getPath(QuestionIdEnum.LoanType)} element={<WhatTypeOfLoan loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.CreditScore)} element={<CreditRating loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.PropertyValue)} element={<PropertyValue loanLead={leadState} updateAndStoreLead={updateLead}/>}/>
                  <Route path={getPath(QuestionIdEnum.MortgageBalance)} element={<MortgageBalance loanLead={leadState} updateAndStoreLead={updateLead}/>} />   
                  <Route path={getPath(QuestionIdEnum.BorrowCash)} element={<BorrowCash loanLead={leadState} updateAndStoreLead={updateLead}/>}/>
                  <Route path={getPath(QuestionIdEnum.ExistLoan)} element={<ExistingLoan loanLead={leadState} updateAndStoreLead={updateLead}/>}/>
                  <Route path={getPath(QuestionIdEnum.CurrentInterest)} element={<CurrentInterestRate loanLead={leadState} updateAndStoreLead={updateLead}/>}/>
                  <Route path={getPath(QuestionIdEnum.DownPayment)} element={<DownPayment loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.MinDownPayment)} element={<MinDownPayment loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.PropertyDescription)} element={<PropertyDescription loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.FoundHome)} element={<FoundHome loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.WantAgent)} element={<WantAgentMatch loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.WhenBuyHome)} element={<WhenBuyHome loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.MilitaryService)} element={<MilitaryService loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.Bankruptcy)} element={<Bankruptcy loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.VerifyIncome)} element={<VerifyIncome loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.LateMortgage)} element={<LateMortgagePayments loanLead={leadState} updateAndStoreLead={updateLead}/>}/>   
                  <Route path={getPath(QuestionIdEnum.PropertyLocation)} element={<PropertyLocation loanLead={leadState} updateAndStoreLead={updateLead}/>}/>
                  <Route path={getPath(QuestionIdEnum.LeadLocation)} element={<LeadLocation loanLead={leadState} updateAndStoreLead={updateLead}/>}/>
                  <Route path={getPath(QuestionIdEnum.BasicInfo)} element={<BasicInformation loanLead={leadState} updateAndStoreLead={updateLead}/>}/>                    
                </Route>               
                {/** Thank You Page */}
                <Route element={<ThankYouLayout/>}>
                  <Route path={getPath(QuestionIdEnum.ThankYou)} element={<ThankYou loanLead={leadState} updateAndStoreLead={updateLead}/>}/>
                </Route>
                {/** Privacy Policies and Disclaimers */}
                <Route>
                  <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                  <Route path='/terms-service' element={<TermsOfService/>} />
                  <Route path='/disclaimer' element={<Disclaimer/>} />
                  <Route path='/contact' element={<Contact/>} />
                </Route>
                {/**Route not found redirect to the current question */}
                <Route path='*' element={<Navigate to={getCurrentQuestionRoute()} replace/>} />
              </Routes>                          
        </div>                 
  );
}

export default App;
