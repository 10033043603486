import { ExistingLoanEnum } from "../../enums/ExistingLoanEnum";

export type currentLoanType = {
    /**
     * The current interest rate for the lead's current loan
     * possible rates: 3.5, 4.5, 4.75, 5, 5.25 
     */
    currentInterestRate:number

    /**
     * The type of existing loan the lead has
     */
    existingLoan:ExistingLoanEnum

    /**
     * The lead's mortgage balance
     */
    mortgageBalance:number
}

export const defaultCurrentLoanFields:currentLoanType = {
    currentInterestRate: 0,
    existingLoan: ExistingLoanEnum.Other,
    mortgageBalance: 0,
}