import { StringHelper } from "../../helpers/StringHelper";
import { ClientTypeEnum } from "../enums/ClientTypeEnum";
import { CreditScoreEnum } from "../enums/CreditScoreEnum";
import { ExistingLoanEnum } from "../enums/ExistingLoanEnum";
import { HomePurchaseTimeEnum } from "../enums/HomePurchaseTimeEnum";
import { LateMortgageEnum } from "../enums/LateMortgageAmountEnum";
import { LeadBuyerEnum } from "../enums/LeadBuyerEnum";
import { LeadStatus } from "../enums/LeadStatus";
import { LoanTypeEnum } from "../enums/LoanTypeEnum";
import { PropertyTypeEnum } from "../enums/PropertyTypeEnum";
import { QuestionIdEnum } from "../enums/QuestionIdEnum";
import LoanLead from "../LoanLeads/LoanLead";
import { PathItem } from "../PathItems/PathItem";

/**
 * The request type that needs to be sent to insertLead and postToLeadBuyers azure function
 */
type LoanLeadRequestType = {
    id:string
    LeadVendor:LeadBuyerEnum
    ip:string
    firstName:string
    lastName:string
    email:string
    phone:string
    address:string
    state:string | null
    city:string | null
    zip:string | null
    basicInfoState:string | null
    basicInfoCity:string | null
    basicInfoZip:string | null
    cid:ClientTypeEnum
    ppcid:string
    leadID:string | null
    product:LoanTypeEnum
    captureTime:string
    creditScore:CreditScoreEnum
    leadStatus:LeadStatus
    loanValue:number
    realLoanValue:number
    propertyValue:number
    percentDown:number
    downPayment:number
    canMinDownPay:boolean
    borrowCash:boolean
    propertyDescription:PropertyTypeEnum
    foundHome:boolean
    militaryService:boolean
    homePurchaseTime:HomePurchaseTimeEnum
    existingLoanType:ExistingLoanEnum
    bankruptcyOrForeclose:boolean
    verifyIncome:boolean
    lateMortgageAmount:LateMortgageEnum
    mortgageBalance:number
    currentInterestRate:number
    workflow:PathItem[]
    currentStep:number
    clickedCreditScore:boolean
    UserAgent:string
    SRToken:string
    TrustedFormToken:string
    TrustedFormCertUrl:string
    TrustedFormPingUrl:string
    ClaimID:string
    UniversalLeadID:string | null
    completedForm:boolean
    WantAgentMatch:boolean
    LastPageVisited:QuestionIdEnum | null
}

/**
 * Loan lead request class
 */
export class LoanLeadRequest {

    /**
     * Converts LoanLeads to suitable LoanLead request objects
     * TODO look for a easier and cleaner way to do this
     *  */ 
    public static convertLeadToRequest(lead:LoanLead) {
        const convertedLead:LoanLeadRequestType = {
            id: lead.id,
            LeadVendor: LoanLead.getLeadBuyer(lead),
            ip: lead.analytics.IPAddress,
            firstName: lead.borrower.basicInfo.FirstName,
            lastName: lead.borrower.basicInfo.LastName,
            email: lead.borrower.basicInfo.Email,
            phone: lead.borrower.basicInfo.Phone,
            address: lead.borrower.basicInfo.Address,
            state: StringHelper.convertEmptyStringToNull(lead.property.state),
            city: StringHelper.convertEmptyStringToNull(lead.property.city),
            zip: StringHelper.convertEmptyStringToNull(lead.property.zip),
            basicInfoState: StringHelper.convertEmptyStringToNull(lead.borrower.basicInfo.BasicInfoState),
            basicInfoCity: StringHelper.convertEmptyStringToNull(lead.borrower.basicInfo.BasicInfoCity),
            basicInfoZip: StringHelper.convertEmptyStringToNull(lead.borrower.basicInfo.BasicInfoZip),
            cid: lead.analytics.cid,
            ppcid: lead.analytics.ppcid,
            leadID: lead.thirdPartyRequirements.leadID,
            product: lead.product,
            captureTime: new Date(lead.analytics.captureTime).toUTCString(),
            creditScore: lead.borrower.creditScore,
            leadStatus: lead.analytics.leadStatus,
            loanValue: lead.getLoanValue(),
            realLoanValue: lead.getLtvLoanValue(),
            propertyValue: lead.propertyValue,
            percentDown: lead.percentDown,
            downPayment: lead.downPayment,
            canMinDownPay: lead.newLoan.canMinDownPay,
            borrowCash: lead.borrower.borrowCash,
            propertyDescription: lead.property.propertyDescription,
            foundHome: lead.newLoan.foundHome,
            militaryService: lead.borrower.militaryService,
            homePurchaseTime: lead.property.homePurchaseTime,
            existingLoanType: lead.currentLoan.existingLoan,
            bankruptcyOrForeclose: lead.borrower.bankruptcyOrForeclose,
            verifyIncome: lead.newLoan.verifyIncome,
            lateMortgageAmount: lead.borrower.lateMortgageAmount,
            mortgageBalance: lead.currentLoan.mortgageBalance,
            currentInterestRate: lead.currentLoan.currentInterestRate,
            workflow: lead.analytics.workflow,
            currentStep: lead.analytics.currentStep,
            clickedCreditScore: lead.analytics.clickedCreditScore,
            UserAgent: lead.analytics.UserAgent,
            SRToken: lead.thirdPartyRequirements.SRToken,
            TrustedFormToken: lead.thirdPartyRequirements.TrustedFormToken,
            TrustedFormCertUrl: lead.thirdPartyRequirements.TrustedFormCertUrl,
            TrustedFormPingUrl: lead.thirdPartyRequirements.TrustedFormPingUrl,
            ClaimID: lead.thirdPartyRequirements.ClaimID,
            UniversalLeadID: StringHelper.convertEmptyStringToNull(lead.thirdPartyRequirements.UniversalLeadID),
            completedForm: lead.analytics.completedForm,
            WantAgentMatch: lead.newLoan.WantAgentMatch,
            LastPageVisited: lead.analytics.LastPageVisited,
        }         

        return convertedLead;
    }
}