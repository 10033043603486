import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TexcorpController } from "../controllers/TexcorpController";
import { ClientTypeEnum, GetCidFromUrlParams, StringToClientTypeEnum } from "../models/enums/ClientTypeEnum";
import { QuestionIdEnum } from "../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../models/enums/QuestionIdToRoute";
import { ppcidDictionary } from "../models/LoanLeads/analytics/PpcidDictionary";
import LoanLead from "../models/LoanLeads/LoanLead";
import { PathItem } from "../models/PathItems/PathItem";

const texcorpController = new TexcorpController();

/**
 * Hook for when a question mount to 
 * update the state, work path, and insert the lead to the db
 * PPCID and CID get set here also because people may bookmark a question and 
 * come back to it at some later date so we need to get the PPCID and CID not only on the 
 * home page but on each question, and on the thank you page
 * @param lead 
 */
const useOnQuestionMount = (
    lead:LoanLead, 
    questionId:QuestionIdEnum,
    updateLeadState:(loanLead:LoanLead) => void,    
    isOnThankYouPage?:boolean
) => {

        /**
        * Creates a new loan lead (to not mutate state) and add the 
        * question to its work path
        */
        const setLeadWorkPath = () => {
            lead = new LoanLead(lead);            
            lead = PathItem.addPathItemToWorkflow(lead, questionId); 
            
            const cid = GetCidFromUrlParams();

            lead.analytics.cid = cid;

            lead.analytics.ppcid = ppcidDictionary[cid].toString();;
            
            lead.analytics.currentStep = questionId;           
        }

        /**
         * When a question mount we update the workflow path, update the lead, 
         * and send the lead to the database to be update for our records (not submitted to peklava or leadpoint)
         */
        return useEffect(() => {
            async function onQuestionMount() {                

                setLeadWorkPath();
                
                updateLeadState(lead);

                await texcorpController.insertLoanLead(lead);
            }
            onQuestionMount();
        },[]);
}

export default useOnQuestionMount;