export enum LeadStatus {    
    Unknown = 0,    
    OK = 1,    
    Matched = 2,    
    Unmatched = 3,    
    Invalid = 4,
    ManualReview = 5,    
    Duplicate = 6,    
    Error = 10
}
