export enum ClientTypeEnum {
    Unknown = 0,
    FHA = 1,
    FHALOAN = 2,
    FHANEWSBLOG = 3,
    HIPOTECA123 = 4
}

export const ClientTypeToWebsite = {
    [ClientTypeEnum.FHA]: "https://fha.com/",
    [ClientTypeEnum.FHALOAN]: "https://fhaloan.com/",
    [ClientTypeEnum.FHANEWSBLOG]: "https://www.fhanewsblog.com/",
    [ClientTypeEnum.HIPOTECA123]: "https://www.hipoteca123.com/",
    [ClientTypeEnum.Unknown]: `/${window.location.search}`,
}

export const StringToClientTypeEnum = (strClient:string | null) => {
    
    if(strClient == null)
        return ClientTypeEnum.Unknown

    strClient = strClient.toUpperCase();

    return ClientTypeEnum[strClient as keyof typeof ClientTypeEnum] ?? ClientTypeEnum.Unknown;
}

export const GetCidFromUrlParams = () =>  {    
    const queryParams = new URLSearchParams(window.location.search);
    
    const cidString = queryParams.get("cid") || queryParams.get("CID");
    
    const cid = StringToClientTypeEnum(cidString);    
    
    return cid;
}