import { useEffect } from "react";
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useFields from "../../../hooks/UseFields";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { HomePurchaseTimeEnum } from "../../../models/enums/HomePurchaseTimeEnum";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

const WhenBuyHome = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.WhenBuyHome;    

    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.MilitaryService];    

    const getPrevQuestion = () => {   
        const {newLoan:{foundHome}} = loanLead;
        
        return foundHome ? 
        QuestionIdToRoute[QuestionIdEnum.FoundHome] :
        QuestionIdToRoute[QuestionIdEnum.WantAgent]
    }
    
    const previousQuestion = getPrevQuestion();    
    
    const [fieldState, , ,handleEnumChange] = useFields(loanLead.property.homePurchaseTime)

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead)

    useEffect(() => {
        function onFieldStateChange() {
            const whenBuyHome = fieldState;

            const lead:LoanLead = DeepCopy.copy(loanLead);

            lead.property.homePurchaseTime = whenBuyHome;

            updateAndStoreLead(lead);
        }

        onFieldStateChange();
    }, [fieldState]);

    /**
     * Check if the radio button is selected or not
     */
    const checkIfChecked = (purchaseTime:HomePurchaseTimeEnum) => {
        return fieldState === purchaseTime;
    }
    
    return (
        <>
            <div className="question-header-container">
                <h3 className="question-header-small">Your Timeframe to Purchase?</h3>
                <h3 className="question-header-medium">When Are You Looking to Buy?</h3>
                <h3 className="question-header">When Are You Likely to Buy a Home?</h3>
            </div>

            <div className="form-element">
                <div className="btn-container-4">
                    <div>
                        <input 
                            checked={checkIfChecked(HomePurchaseTimeEnum.days30)} 
                            type="radio" 
                            value={HomePurchaseTimeEnum.days30} 
                            id="thirtyDays" 
                            name="WhenBuyHome" 
                            onChange={(e) => handleEnumChange(e, HomePurchaseTimeEnum)} 
                        />
                        <label className="btn-30-days btn-three-question" htmlFor="thirtyDays" aria-label="30 Days"></label>

                        <input 
                            checked={checkIfChecked(HomePurchaseTimeEnum.days60)} 
                            type="radio" 
                            value={HomePurchaseTimeEnum.days60} 
                            id="sixtyDays" 
                            name="WhenBuyHome" 
                            onChange={(e) => handleEnumChange(e, HomePurchaseTimeEnum)}
                        />
                        <label className="btn-60-days btn-three-question" htmlFor="sixtyDays" aria-label="60 Days"></label>
                    </div>

                    <div>        
                        <input 
                            checked={checkIfChecked(HomePurchaseTimeEnum.days90)} 
                            type="radio" 
                            value={HomePurchaseTimeEnum.days90} 
                            id="ninetyDays" 
                            name="WhenBuyHome" 
                            onChange={(e) => handleEnumChange(e, HomePurchaseTimeEnum)}
                        />
                        <label className="btn-90-days btn-three-question" htmlFor="ninetyDays" aria-label="90 Days"></label>

                        <input 
                            checked={checkIfChecked(HomePurchaseTimeEnum.noTimeConstraint)} 
                            type="radio"
                            value={HomePurchaseTimeEnum.noTimeConstraint} 
                            id="noTimeDays" 
                            name="WhenBuyHome" 
                            onChange={(e) => handleEnumChange(e, HomePurchaseTimeEnum)}
                        />
                        <label className="btn-90-plus-days btn-three-question" htmlFor="noTimeDays" aria-label="90 + Days"></label>
                    </div> 
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default WhenBuyHome;