import '../../styles/pages/PolicyAndTermsStyles.css';

const Disclaimer = () => {
    return (
            <div className="privacy-page-container">
                <p><strong>FHALoan.com Disclaimer</strong></p>
                <p>
                    We are a publisher of mortgage news and information, and sell advertisements for mortgage products to marketing companies or lenders. We are not a lender, we do not offer credit products.  WE DO NOT OFFER OR HAVE ANY AFFILIATION WITH LOAN MODIFICATION, FORECLOSURE PREVENTION, PAYDAY OR SHORT-TERM LOAN SERVICES. WE ARE NOT A GOVERNMENT WEBSITE, NOR DO WE HAVE ANY AFFILIATION WITH THE FEDERAL GOVERNMENT. WE ARE A PRIVATELY-OWNED WEBSITE THAT PUBLISHES INDEPENDENT INFORMATION ABOUT MORTGAGE PROGRAMS AND EARNS MONEY BY SELLING ADVERTISING. We do not, nor do our advertisers, charge any fee or require anything other than submission of qualifying information for comparison shopping services offered in the advertisements. We do not ask users to surrender or transfer title. We do not ask the user to bypass the lender. We do not encourage users not to contact their lender, lawyer, credit counselor, or housing counselor.
                </p>
            </div>
    )    
}

export default Disclaimer;