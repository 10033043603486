import { useContext } from "react"
import { LoanLeadContext } from "../../../contexts/LoanLeadContext"
import { LeadBuyerEnum } from "../../../models/enums/LeadBuyerEnum";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import LeadPointStatement from "./LeadPointStatement";
import PeklavaStatement from "./PeklavaStatement";

const BasicInfoStatement = (props:{loanLead: LoanLead}) => {    
    const {loanLead} = props;

    const getTCPAStatement= () => {
        const leadBuyer = new LoanLead(loanLead).leadBuyer;
        
        return leadBuyer === LeadBuyerEnum.Peklava ? <PeklavaStatement/> : <LeadPointStatement/>
    }
    return (
        <div className="privacy-statements-container">
            {getTCPAStatement()}
        </div>        
    )
}

export default BasicInfoStatement