import { ClientTypeToWebsite, StringToClientTypeEnum } from "../../models/enums/ClientTypeEnum";
import { QuestionIdEnum } from "../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../models/enums/QuestionIdToRoute";
import "../../styles/headers/Header.css";


const Header = () => {    
    //Logo is swapped with CSS when cid is changed
    const logoImgSrc = `${process.env.PUBLIC_URL}/images/fha/fha-logo.png`;
    
    const altText = "FHA.com: Home Purchase and Refinance Loans";

    const getLogoHref = () => {
        let href = `${window.location.pathname}${window.location.search}`;
        
        const currentPath = window.location.pathname;

        //If on the thank you page we want to allow the user to go back to the site where they came from
        if(currentPath === '/' + QuestionIdToRoute[QuestionIdEnum.ThankYou]) {
            const queryParams = new URLSearchParams(window.location.search);
                        
            const cid = queryParams.get("cid");    		
            
            const convertedCID = StringToClientTypeEnum(cid);
            
            const url = ClientTypeToWebsite[convertedCID];
            
            if(url)
                href = url;
        }

        return href;
    }

    return (
        <>
        <section className="header-section">
            <header className="logo-header">
                <div className="logo-container">
                    <a href={getLogoHref()}>
                        <img className="logo" src={logoImgSrc}
                            alt={altText} width="360" height="120" />
                    </a>
                </div>
            </header>
        </section>
        <section className="disclaimer-section">
            <div className="disclaimer">
                <span className="text-disclaimer">
                    FHALoan.com is a private company, is not a government agency, and does not make loans.
                </span>
            </div>
        </section>
        </>
    )
}

export default Header;