import { FormEvent, useState } from "react"
import { SafeParseReturnType } from "zod"
import { StringHelper } from "../helpers/StringHelper"
import { basicInfoFields, basicInfoType } from "../models/formFields/BasicInformationFields"
import { BasicInfoSchema } from "../models/validationSchemas/BasicInfoSchema"

export type fieldErrorsState = {
    [key:string]: {
        hasErrors:boolean,
        errors:string[]
    }
}

export type formErrorInput = {
    fieldName:string,
    fieldValue:string,
    errMsg:string
}

export const errorName = {
    emptyError: "emptyError",
    lengthError: "lengthError",
    phoneError: "phoneError",
    emailError: "emailError"
}

/**
 * Validates and keep track of error messages state
 */
const useFieldErrors = (defaultFields:fieldErrorsState) => {

    const [errorState, setErrorState] = useState(defaultFields);

    const updateErrorState = (fieldName:string, validationRes:SafeParseReturnType<string, string>) => {
            if(!validationRes.success){
                const errors = validationRes.error.formErrors.formErrors;
                setErrorState(prevState => ({
                    ...prevState,
                    [fieldName]: {
                        hasErrors: true,
                        errors
                    }
                }));
            } else {
                setErrorState(prevState => ({
                    ...prevState,
                    [fieldName]: {
                        hasErrors: false,
                        errors: []
                    }
                }));
        }
    }

    /**
     * Validates all basic info fields
     * @param basicInfo 
     * @returns 
     */
    const validateAllFields = (basicInfo:basicInfoType) => {
        const results =BasicInfoSchema.allFieldsSchema.safeParse(basicInfo);

        if(!results.success) {
            
            const errorState:fieldErrorsState = {}
            results.error.issues.forEach((issue) => {
                const fieldName = issue.path[0];

                const errorObj = {
                    hasErrors:true,
                    errors: [issue.message]
                }

                if(errorState[fieldName]) {
                    return;
                } else {
                    errorState[fieldName] = {...errorObj};
                }
            })

            setErrorState(prevState => ({
                ...prevState,
                ...errorState
            }));
        }

        return results.success;
    }
    
    /**
     * Checks a input value against its coordinating schema and sets the error state;
     * @param e 
     */
    const handleErrorChange = (e:FormEvent<HTMLInputElement>) => {
        const inputName = e.currentTarget.name;
        
        let inputValue = e.currentTarget.value;

        let results:null | SafeParseReturnType<string, string> = null; 

        if(inputName === basicInfoFields.Phone)
            inputValue = StringHelper.removeNonDigits(inputValue);

        switch (inputName) {
            case basicInfoFields.FirstName: 
                results = BasicInfoSchema.firstNameSchema.safeParse(inputValue);
                break;

            case basicInfoFields.LastName: 
                results = BasicInfoSchema.lastNameSchema.safeParse(inputValue);
                break;
                
            case basicInfoFields.Address: 
                results = BasicInfoSchema.addressSchema.safeParse(inputValue);
                break;
                
            case basicInfoFields.BasicInfoCity: 
                results = BasicInfoSchema.citySchema.safeParse(inputValue);
                break;            

            case basicInfoFields.BasicInfoState: 
                results = BasicInfoSchema.stateSchema.safeParse(inputValue);
                break;            

            case basicInfoFields.BasicInfoZip: 
                
                results = BasicInfoSchema.zipSchema.safeParse(inputValue);
                break;  

            case basicInfoFields.Phone: 
                results = BasicInfoSchema.phoneSchema.safeParse(inputValue);
                break;  

            case basicInfoFields.Email: 
                results = BasicInfoSchema.emailSchema.safeParse(inputValue);
                break;  
        }

        if(results)
            updateErrorState(inputName, results);
            
    }

    return [errorState, setErrorState,handleErrorChange, validateAllFields];
}

export default useFieldErrors