import { useLocation, useNavigate } from 'react-router-dom';
import { QuestionIdEnum } from '../../models/enums/QuestionIdEnum';
import { QuestionIdToRoute } from '../../models/enums/QuestionIdToRoute';
import { basicNavigationProps } from './NavigationButtons';
import '../../styles/buttons/ButtonAndInputStyles.css';

type previousButtonProps = {
    nextPath:string
} & basicNavigationProps;

const PreviousButton = (props:previousButtonProps) => {
    const previousBtnSrc = `${process.env.PUBLIC_URL}/images/global/Buttons/back-button.png`;
    
    const navigation = useNavigate();
    
    const location = useLocation();
    
    const {loanLead, nextPath} = props;
    
    let {path} = props;
     
    const handleSkip = () => {                

        if(!loanLead)
            return path;

        //We want the previous question which is why the - 2
        const length = loanLead.analytics.workflow.length - 2;
        if(length < 0) {
            path = QuestionIdToRoute[QuestionIdEnum.LoanType];        
        }

        if(length >= 0) {
            
            const lastQuestion = loanLead.analytics.workflow[length];

            const questionPath = QuestionIdToRoute[lastQuestion.componentID];        

            if(questionPath !== path && questionPath !== nextPath)
                path = QuestionIdToRoute[QuestionIdEnum.LoanType];
        }

        return path;
    }

    const handleOnClick = () => {
        // path = handleQuestionSkipping(path, nextPath, loanLead);
        path = handleSkip();
        navigation(`/${path}${location.search}`)                
    }

    return (
        <span aria-label="Back Button"  onClick={handleOnClick}>
            <img className="btn-prev" aria-label="Back Button" src={previousBtnSrc} width="40" height="61" />
        </span>
    )
}

export default PreviousButton;