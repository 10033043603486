import { LeadStorage } from "../models/LoanLeads/LeadStorage";
import LoanLead from "../models/LoanLeads/LoanLead";
import { CryptoHelper } from "./UtilityHelper";

/**
 * Stores data in local or session storage
 */
 export class StorageHelper {
    private static loanLeadStorageKey = "lead";

    private static cryptoHelper = new CryptoHelper();

    /**
     * Stores a loan lead in session storage     
     * @param loanLead 
     */
    public static storeLead = (loanLead:LoanLead) => {   
        const leadStore = LeadStorage.convertLeadToStorage(loanLead);             

       const cipherStr = this.cryptoHelper.encrypt(leadStore);
        
        sessionStorage.setItem(this.loanLeadStorageKey, cipherStr);
    }

    /**
     * Retrieves a loan lead from storage or creates a new lead 
     * if one is not in storage
     * TODO decrypt this data
     */
    public static retrieveLead = () => {
        const leadCipher = sessionStorage.getItem(this.loanLeadStorageKey);

        const leadStorage = this.cryptoHelper.decrypt(leadCipher);
        
        if(leadStorage) {

            return LeadStorage.convertStorageToLead(leadStorage);            
        }
        
        const lead = new LoanLead();

        StorageHelper.storeLead(lead);

        return lead;
    }
}