export enum LoanTypeEnum {
    Purchase = 0,
    Refinance = 1
}



export const queryStringProductToEnum = (queryStr:string | null) => {        
    if(queryStr === "PP_NEWHOME")
        return LoanTypeEnum.Purchase

    if(queryStr === "PP_REFI") 
        return LoanTypeEnum.Refinance

    return null;
}