import { useEffect, useState } from 'react';
import NavigationButtons from '../../../components/buttons/NavigationButtons';
import { DeepCopy } from '../../../helpers/DeepCopy';
import UseFields from '../../../hooks/UseFields';
import useOnQuestionMount from '../../../hooks/UseOnQuestionMount';
import { LoanTypeEnum } from '../../../models/enums/LoanTypeEnum';
import { QuestionIdEnum } from '../../../models/enums/QuestionIdEnum';
import { QuestionIdToRoute } from '../../../models/enums/QuestionIdToRoute';
import LoanLead from '../../../models/LoanLeads/LoanLead';
import { LoanLeadProps } from '../../../models/propTypes/LoanLeadProps';
import '../../../styles/questions/QuestionStyles.css';

type WhatTypeOfLoanState = {
    selectedLoanType:LoanTypeEnum
}

/**
 * Question that allow the lead to select between purchase or refinance.
 * This question can be skipped if the url query string contain values for
 * loan_purpose or product.
 * Loan Purpose: 1 for purchase and 2 for refinance (don't seem to do anything)
 * product: PP_REFI or PP_NEWHOME
 */
const WhatTypeOfLoan = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.LoanType; 

    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.CreditScore];
    
    const defaultState:WhatTypeOfLoanState = {
        selectedLoanType: LoanTypeEnum.Purchase
    }
    
    const defaultRadioInputState = {
        loanType: loanLead.product        
    }
    
    const [state, setState] = useState(defaultState)

    const [fieldData, handleChange] = UseFields(defaultRadioInputState);        

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);    

    useEffect(() => {        
        function onFieldChange() {
            const loanType = Number(fieldData.loanType)
            
            setState(prevState => ({
                ...prevState,
                selectedLoanType:loanType
            })); 
            
            const lead:LoanLead = DeepCopy.copy(loanLead);

            lead.product = loanType;

            updateAndStoreLead(lead);
        }
        onFieldChange();
    }, [fieldData]);

    return ( 
        <>
            <div className="question-content-container">
                <div className="question-header-container">
                    <h3 className="question-header">What Type Of Loan?</h3>
                </div>
                <div className="form-element">
                    <div className="btn-two-container">
                        <div className="radio-btn-container">
                            <input 
                                checked={state.selectedLoanType === LoanTypeEnum.Refinance} 
                                id="refinance" 
                                type="radio" 
                                name="loanType" 
                                value={LoanTypeEnum.Refinance} 
                                onChange={handleChange} 
                            />
                            <label className="btn-refi btn-question" htmlFor="refinance" aria-label="refinance"></label>
                        </div>
                        <div className="radio-btn-container">
                            <input 
                                checked={state.selectedLoanType === LoanTypeEnum.Purchase} 
                                id="purchase" 
                                type="radio" 
                                name="loanType" 
                                value={LoanTypeEnum.Purchase} 
                                onChange={handleChange}
                            />
                            <label className="btn-purchase btn-question" htmlFor="purchase" aria-label="purchase"></label>
                        </div>
                        </div>
                    </div>
                </div>                
                <NavigationButtons 
                    path={nextQuestion}                    
                    nextPath={nextQuestion}
                    prevPath=""
                    isFirstQuestion={true}
                />                
        </>
    )
}



export default WhatTypeOfLoan;