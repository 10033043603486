import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import '../../styles/layouts/Layout.css';

type LayoutProps = {
    children?:React.ReactNode
}

const Layout = (props:LayoutProps) => {
    const {children} = props;
    return (
        <React.Fragment>
            <Header/>
            <div className="content-section">    
                <div className="main-content border-white background-main-color"> 
                    <div className='questions-container'>                                    
                        <Outlet />            
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );    
}

export default Layout