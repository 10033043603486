/**
 * Because questions have been swapped around, 
 * in order to keep analytics working the numbers will appear out of order
 * for example if a new question is added it needs to be given a new number and not the number
 * of an existing question. Otherwise the fall off charts on the analytics dashboard would become meaningless
 */
export enum QuestionIdEnum {
    LoanType = 0,    
    CreditScore = 2,
    PropertyValue = 3,
    DownPayment = 4,
    MortgageBalance = 5,
    MinDownPayment = 6,
    BorrowCash = 7,
    PropertyDescription = 8,
    FoundHome = 9,
    WantAgent = 10,
    MilitaryService = 11,
    WhenBuyHome = 12,
    ExistLoan = 13,
    CurrentInterest = 14,
    Bankruptcy = 15,
    VerifyIncome = 16,
    LateMortgage = 17,
    PropertyLocation = 1,
    LeadLocation = 20, //new question
    BasicInfo = 18,
    ThankYou = 19
}