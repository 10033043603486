import { useMemo, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import Slider, { sliderTrackClass } from "../../../components/sliders/Slider";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { LoanTypeEnum } from "../../../models/enums/LoanTypeEnum";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { defaultPropertyValueIndex, getUiPropertyValues, propertyValues } from "../../../models/propertyValues/PropertyValues";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

/**
 * Component that ask for the home value.
 * After this question is answered the questions get split up between 
 * purchase loan questions and refinance loan questions
 * @param props 
 * @returns 
 */
const PropertyValue = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.PropertyValue;              
    
    /**
     * Property values that will be displayed to the user
     */
    const uiPropertyValues = useMemo(() => (
        getUiPropertyValues()
    ), [])

    const defaultState = {
        selectedPropertyValue: loanLead.propertyValue ? loanLead.propertyValue : propertyValues[defaultPropertyValueIndex]        
    }

    const [state, setState] = useState(defaultState);

    /**
     * Update the global lead state with new values from this question
     * @param propertyValue 
     * @returns 
     */
    const updateMainLeadState = (propertyValue?:number) => {

        const lead:LoanLead = DeepCopy.copy(loanLead);

        lead.propertyValue = propertyValue ?? state.selectedPropertyValue;        

        updateAndStoreLead(lead);

        return lead;
    }

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    /**
     * Update the PropertyValue component and global lead state
     * @param propertyValue 
     */
    const updateState = (propertyValue:number) => {
        setState(prevState => ({
            ...prevState,
            selectedPropertyValue:propertyValue
        }));
        
        updateMainLeadState(propertyValue);
    }

    /**
     * Handles the input select change
     * @param e 
     */
    const handleOnSelectChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.currentTarget.value);

        if(Number.isNaN(value))
            return;

        updateState(value);
    }

    /**
     * handle the slider's input change
     * @param index 
     */
    const handleSliderChange = (index:number) => {
        const propertyValue = propertyValues[index];

        updateState(propertyValue);
    }    
    
    /**
     * Get string value that should be displayed in the UI
     */
    const displayPropertyValue = (index:number) => {
        const currentPropertyVal = propertyValues[index];
        
        const valueToDisplay = uiPropertyValues[currentPropertyVal];
        
        return valueToDisplay;
    }

    /**
     * get the index from propertyValues based on our current selected property value
     * @returns 
     */
    const getPropertyValueIndex = () => {
        const {selectedPropertyValue} = state;

        const index = propertyValues.findIndex(val => val === selectedPropertyValue);

        return index;
    }

    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.CreditScore];

    /**
     * Check loan lead for loan type and decides the next question
     */
    const getNextQuestion = () => {
        const {product} = loanLead;
        // If the loan type was purchase go to down payment else go to mortgage balance (because the loan if refinance)
        return product === LoanTypeEnum.Purchase ?  
            QuestionIdToRoute[QuestionIdEnum.DownPayment] : QuestionIdToRoute[QuestionIdEnum.MortgageBalance];
    }

    const nextQuestion = getNextQuestion();

    return (
        <>
            <div className="question-content-container">
                <div className="question-header-container">
                    <h3 className="question-header">What Is The Home's Value?</h3>
                    <span className="text-center tip">(It's OK to estimate.)</span>
                </div>
                <div className="form-element select-mobile-container">
                    <select className="input-select-mobile input-border" value={state.selectedPropertyValue} onChange={handleOnSelectChange}>
                        {
                            Object.entries(uiPropertyValues).map(([key, value]) => (
                                <option 
                                    key={uuidv4()}
                                    value={key}
                                >
                                    {value as string}
                                </option>
                            ))
                        }
                    </select>
                    <Slider 
                        sliderTrack={sliderTrackClass.default}
                        showProgress={true}
                        min={0}
                        max={propertyValues.length - 1}
                        defaultValue={getPropertyValueIndex() }
                        setValue={handleSliderChange}
                        displayInSlider={displayPropertyValue}
                    />
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default PropertyValue;