import {v4 as uuidv4} from "uuid"
import * as CryptoJS from "crypto-js";

import { LeadStorage } from "../models/LoanLeads/LeadStorage";

/**
 * Static class for performing AES encryption and decryption
 * The reason we need AES encryption is because we are storing user answers
 * in session storage. Though it can be argued the data we are storing is not sensitive it is to be safe
 */
export class CryptoHelper {
    private key = process.env.REACT_APP_STORAGE_KEY || "25065a91f964f81cbfbea7232d50d9f922097088";

    public encrypt = (data:LeadStorage) => {
        const jsonStr = JSON.stringify(data);

        return CryptoJS.AES.encrypt(jsonStr, this.key).toString();
    }

    public decrypt = (cipherStr:string|null): LeadStorage | null => {
        if(!cipherStr)
            return null;

        const decryptedStr = CryptoJS.AES.decrypt(cipherStr, this.key).toString(CryptoJS.enc.Utf8);

        try {            
            return JSON.parse(decryptedStr)
        } catch (error) {
            //TODO log it and remove
            console.error(error);
            return null;
        }
    }
}