/**
 * Because Jerald do not want Google Analytics to be used in the React way 
 * (https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications)
 * We are using this navigation helper method to navigate through the form like a multi page app
 * So google analytics will work like any other regular website
 */
export class NavigationHelper {
    
    /**
     * Causes a page load when navigating
     * @param path 
     */
    public static pageLoadNavigation(path:string, replace?:boolean, stateObj?:any) {
        if(replace) {
            window.history.replaceState({}, '',`${path}`);
            
        } else {

            window.history.pushState(stateObj, '',`${path}`);
        }

        window.location.reload();
    }
}