import { useLocation, useNavigate } from 'react-router-dom';
import { NavigationHelper } from '../../helpers/NavigationHelper';
import { QuestionIdEnum } from '../../models/enums/QuestionIdEnum';
import { QuestionIdToRoute } from '../../models/enums/QuestionIdToRoute';
import LoanLead from '../../models/LoanLeads/LoanLead';
import { basicNavigationProps } from './NavigationButtons';
import '../../styles/buttons/ButtonAndInputStyles.css';

export type continueButtonProps = {
    prevPath?:string
    nextPath:string
} & basicNavigationProps;

/**
 * Handles the case were a user is skipping questions by setting the path to 
 * their last answered question or to the first question
 * @returns path string
 */
export const handleQuestionSkipping = (currentPath:string, nextPath:string, prevPath?:string,  loanLead?:LoanLead):string => {
    
    if(!loanLead)
        return currentPath;

    //We want the previous question which is why the - 2
    const length = loanLead.analytics.workflow.length - 2;

    if(length < 0) {
        currentPath = QuestionIdToRoute[QuestionIdEnum.LoanType];        
    }

    if(length >= 0) {
        
        const lastQuestion = loanLead.analytics.workflow[length];

        const questionPath = QuestionIdToRoute[lastQuestion.componentID];        

        if(questionPath !== prevPath && questionPath !== nextPath)
            currentPath = QuestionIdToRoute[QuestionIdEnum.LoanType];
    }

    return currentPath;
}

/**
 * Continue Button use to continue on to the next question
 */
const ContinueButton = (props:continueButtonProps) => {
    let {disabled, path, loanLead, prevPath, nextPath} = props;     
    
    const navigation = useNavigate();
    
    const location = useLocation();    

    const handleContinueClick = () => {
        if(props.validationCheck) {
            const isInvalid = props.validationCheck();

            if(isInvalid)
                return;
        }

       path = handleQuestionSkipping(path, nextPath, prevPath, loanLead);
       
        NavigationHelper.pageLoadNavigation(`/${path}${location.search}`);

        //Uncomment if you want to go back to react navigation
        // window.history.pushState({}, '',`/${path}${location.search}`);
        // window.location.reload();
       
        // navigation(`/${path}${location.search}`);        
    }

    return (
        
        <button 
            disabled={disabled}
            className="btn-continue" 
            onClick={handleContinueClick} 
            aria-label="Continue">                
        </button>
    )
}

export default ContinueButton;