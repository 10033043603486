/**
 * Extremely important fields that are absolutely necessary for submitting valid leads.
 * If these fields do not get populated correctly then the lead is considered invalid.
 * Jornaya, Peklava, and LeadPoint must have these fields 
 */
export type ThirdPartyRequiredFields = {
    /**
     * The identifier sent back from the LeadPoint API to match the submission to the 3rd party
     */
    leadID:string | null
    
    /**
     * The secure rights token that is populated by one of the google task manager
     */
    SRToken:string
    
    // All Trust properties must be populated by a script given by Jornaya

    /**
     * Token from TCPA scripts
     */
    TrustedFormToken:string
    
    /**
     * The TCPA CertUrl used to get the claim ID
     */
    TrustedFormCertUrl:string

    /**
     * Trusted form ping url that is need for the trust form cert
     */
    TrustedFormPingUrl:string

    /**
     * The claim ID to ensure a lead has consented and have been claimed
     * ClaimID is gotten from the trusted form cert url on the backend
     */
    ClaimID:string

    /**
     * Jornaya leadID used so they dont get duplicate leads
     */
    UniversalLeadID:string
}

export const defaultThirdPartyRequiredFields = {
    leadID: null,
    SRToken: "",
    TrustedFormToken: "",
    TrustedFormCertUrl: "",
    TrustedFormPingUrl: "",
    ClaimID: "",
    UniversalLeadID: ""
}