/**
 * lead's basic information such as address, phone, etc
 * phone, address, email, first name, and last name are not stored by BP.
 * But it is used by Peklava and LeadPoint
 */
export type BasicInfo = {
    FirstName:string
       
    LastName:string
        
    Email:string
        
    Phone:string
            
    Address:string
    
    BasicInfoState:string
    
    BasicInfoCity:string
    
    BasicInfoZip:string
}

export const defaultBasicInfo:BasicInfo = {
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Address: "",
    BasicInfoState: "",
    BasicInfoCity: "",
    BasicInfoZip: ""
}