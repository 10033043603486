import React, { useEffect, useRef, useState } from "react";
import "./Slider.css";

export enum sliderTrackClass {
    default = "slider-track-default",
    creditScore = "slider-track-credscore"
    
} 

type sliderProps = {
    /**
     * The class that control the slider's inside
     */
    sliderTrack:sliderTrackClass
    /**
     * The blue bar that show behind the slider thumb
     */
    showProgress:boolean 

    min:number

    max:number

    defaultValue?:number

    setValue?:(param:any) => void 

    displayInSlider?:(currentVal:number)  => string | undefined
    updateIndex?:(index:number)  => void
}

type sliderState = {
    currentIndex:number
}

const Slider = (props:sliderProps) => {
    const {sliderTrack, showProgress, defaultValue, min, max, setValue} = props;
    const sliderThumbSrc = `${process.env.PUBLIC_URL}/images/global/sliders/slider-thumb.png`;
   
    /**  The HTML 5 slider*/
    const sliderRef = useRef<HTMLInputElement>(null);
   
    /**The thumb. Not the HTML 5 thumb, an image which will act as a thumb */
    const thumbRef = useRef<HTMLImageElement>(null);
   
    /** A div that acts as the slider's progress bar*/    
    const progressRef = useRef<HTMLDivElement>(null);

    const defaultState:sliderState  = {
        currentIndex: props.defaultValue ?? 0
    }

    const [state, setState] = useState(defaultState);

    useEffect(() => {
        function onComponentMount() {            
            moveSlider(defaultValue);
        }

        onComponentMount();
    }, []);

    useEffect(() => {
        function onValueChange() {
            
            moveSlider(defaultValue)
        }

        onValueChange();

    }, [defaultValue])

    useEffect(() => {
        
        const debounceFunc = setTimeout(() => {
            if(setValue)
                setValue(state.currentIndex)
        }, 400)
    
        return () => clearTimeout(debounceFunc);

    }, [state.currentIndex])

    const handleInput = (e:React.ChangeEvent<HTMLInputElement>) => {
        let valueStr = e.target.value;

        let value = Number.parseInt(valueStr);

        // if(setValue)
        //     setValue(value);
        
            setState(prevState => ({
                ...prevState,
                currentIndex:value
            }));

        //return SetValue.InvokeAsync(value);

    }

/**
 * Moves the thumb and progress bar on a slider
 * */
function moveSlider(val?:number) {

    if(sliderRef.current && thumbRef.current){
        
        if (val != null) {

            //set the slider to the passed in value
            sliderRef.current.value = `${val}`                
        } 
    
        // Set the default position for the thumb and progress bar
        setPosition(sliderRef.current, thumbRef.current, progressRef.current);
    }
    
}

function setPosition(slider:any, thumb:any, progress:any) {
    
    // Getting the current value from the input range
    const val = slider.value;

    // Get the min if there is one else set as 0
    const min = slider.min ? slider.min : 0;

    // Get the min if there is one else set as 100
    const max = slider.max ? slider.max : 100;

    // Where we plan to move thumb and progress
    const moveTo = Number(((val - min) * 100) / (max - min));

    /** because the HTML 5 slider doesn't go outside the slider track
    we have to make sure to account for that **/
    let newPosition = -7 - (moveTo * .25);

    // Moving the thumb
    thumb.style.left = `calc(${moveTo}% + (${newPosition}px))`;

    // Check if there is a progress bar
    if (progress != null) {

        progress.style.width = `calc(${moveTo}% + (${newPosition}px))`;
    }
}

    return (
        <>
        <div className="slider-container">
            <div className={`slider-track ${sliderTrack}`}>
                <input 
                    ref={sliderRef}
                    aria-label="Slider Input Range" 
                    className="slider" 
                    type="range" 
                    min={min}
                    max={max}
                    defaultValue={defaultValue} 
                    onInput={handleInput} 
                    onChange={() => setPosition(sliderRef.current, thumbRef.current, progressRef.current)}
                    />
                { showProgress &&
                    (
                        <div 
                        ref={progressRef}
                        className="slider-progress" 
                        />
                        )
                    }
                <img 
                    ref={thumbRef}
                    className="slider-thumb" 
                    src={sliderThumbSrc} 
                    width={39} 
                    height={75} 
                    alt="Slider Thumb" 
                    />
            </div>
        </div>
        {
            //@ts-ignore
            props.displayInSlider && (
                <input 
                readOnly
                value={props.displayInSlider(state.currentIndex) }
                className="slider-text input-border" 
                type="text" 
            />
            )
        }

        </>
    )
} 

export default Slider;