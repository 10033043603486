import { useMemo, useState } from "react";
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import Slider, { sliderTrackClass } from "../../../components/sliders/Slider";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { CreditScoreEnum } from "../../../models/enums/CreditScoreEnum";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";
import '../../../styles/questions/CreditRatingStyles.css';
import '../../../styles/questions/QuestionStyles.css';

const CreditRating = (props:LoanLeadProps) => {   
    const {loanLead, updateAndStoreLead} = props;
    
    const questionId = QuestionIdEnum.CreditScore;        

    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.LoanType];
    
    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.PropertyValue];        

    const defaultState = {
        selectedCredScore: loanLead.borrower.creditScore
    }

    const [state, setState] = useState(defaultState);

    /**The amount of enums in the CreditScoreEnum obj */
    const creditRatingLength = useMemo( () => (
        (Object.keys(CreditScoreEnum).length/2) - 1
    ), []);    

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    /**
     * Set a select class for the corresponding credit rating section/div/button
     * note:only shows on mobile
     * @param rating 
     * @returns 
     */
    const setSelectStyle = (rating:CreditScoreEnum) => {
        return state.selectedCredScore === rating ? "selected" : "";
    }     

    /**
     * Handles the creditScore state change
     * @param creditScore 
     */
    const handleChange = (creditScore:CreditScoreEnum) => {        
        setState(prevState => ({
            ...prevState,
            selectedCredScore:creditScore
        }));

        const lead:LoanLead = DeepCopy.copy(loanLead);

        lead.borrower.creditScore = creditScore;

        updateAndStoreLead(lead);
    }

    /**
     * Handle the slider input by reversing the input so that it will match our enum
     * @param value 
     * @returns 
     */
    const handleSliderChange = (value:number) => {
        
        if(value > creditRatingLength)
            return;

        const newValue = creditRatingLength - value;

        if(newValue < 0)
            return;

        handleChange(newValue);        
    }   

    /**
     * Because we are using a slider to get our input and 
     * the credit rating question is from excellent to poor
     * we need to reverse or value to get the correct index
     */
    const getCreditScoreIndex = () => {
        const currentCredit = state.selectedCredScore;        
        
        //Because creditScoreEnum is an enum we need to get half of the values to not get the keys
        const maxIndexLength = (Object.values(CreditScoreEnum).length/2) - 1;

        const index = maxIndexLength - currentCredit;                

        return index;
    }    
    
    return (
        <>
            <div className="question-header-container">
                <h3 className="question-header">Estimate Your Credit Rating</h3>
                <span className="text-center tip">(Most people have "Good" credit)</span>
            </div>
            <div className="form-element">
                <Slider
                    sliderTrack={sliderTrackClass.creditScore}
                    showProgress={false}
                    min={0}
                    max={creditRatingLength}
                    defaultValue={getCreditScoreIndex()} 
                    setValue={handleSliderChange}
                />

                <div className="credscores-text-container">
                    <div 
                        className={`credscores-btn-container 
                        ${setSelectStyle(CreditScoreEnum.Excellent)}`} 
                        onClick={() => handleChange(4)}
                    > 
                        <h3 className="credscore-name">Excellent</h3>
                        <strong className="credscore-label">
                            (680 or Higher)
                        </strong>
                        <span className="credscores-text">
                            Very strong credit history with few credit problems.
                        </span>
                    </div>

                    <div 
                        className={`credscores-btn-container 
                        ${setSelectStyle(CreditScoreEnum.Good)}`}
                        onClick={() => handleChange(CreditScoreEnum.Good)}
                    >
                        <h3 className="credscore-name">Very Good</h3>
                        <strong className="credscore-label">
                            (640 to 679)
                        </strong>
                        <span className="credscores-text">
                            Good consumers falls into this category.
                        </span>
                    </div>
                    <div 
                        className={`credscores-btn-container 
                        ${setSelectStyle(CreditScoreEnum.Average)}`}
                        onClick={() => handleChange(CreditScoreEnum.Average)}
                    >
                        <h3 className="credscore-name">Good</h3>
                        <strong className="credscore-label">
                            (600 to 639)
                        </strong>
                        <span className="credscores-text">
                            Average consumers falls into this category.
                        </span>
                    </div>
                    <div 
                        className={`credscores-btn-container
                        ${setSelectStyle(CreditScoreEnum.Fair)}`}
                        onClick={() => handleChange(CreditScoreEnum.Fair)}
                    >
                        <h3 className="credscore-name">Fair</h3>
                        <strong className="credscore-label">
                            (560 to 599)
                        </strong>
                        <span className="credscores-text">
                            Most people with bad credit fall in this category.
                        </span>
                    </div>
                    <div 
                        className={`credscores-btn-container 
                        ${setSelectStyle(CreditScoreEnum.Poor)}`}
                        onClick={() => handleChange(CreditScoreEnum.Poor)}
                    >
                        <h3 className="credscore-name">Poor</h3>
                        <strong className="credscore-label">
                            (559 or Less)
                        </strong>
                        <span className="credscores-text">
                            There are still options available to you.
                        </span>
                    </div>
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion}    
                loanLead={loanLead}            
            />
        </>
    )
}

export default CreditRating;