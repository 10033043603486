import { useEffect } from "react";
import NavigationButtons from "../../../components/buttons/NavigationButtons";
import { DeepCopy } from "../../../helpers/DeepCopy";
import useFields from "../../../hooks/UseFields";
import useOnQuestionMount from "../../../hooks/UseOnQuestionMount";
import { QuestionIdEnum } from "../../../models/enums/QuestionIdEnum";
import { QuestionIdToRoute } from "../../../models/enums/QuestionIdToRoute";
import LoanLead from "../../../models/LoanLeads/LoanLead";
import { LoanLeadProps } from "../../../models/propTypes/LoanLeadProps";

const MinDownPayment = (props:LoanLeadProps) => {
    const {loanLead, updateAndStoreLead} = props;

    const questionId = QuestionIdEnum.MinDownPayment;

    const previousQuestion = QuestionIdToRoute[QuestionIdEnum.DownPayment];
    
    const nextQuestion = QuestionIdToRoute[QuestionIdEnum.PropertyDescription];    

    const [fieldState,,handleBoolChange] = useFields(loanLead.newLoan.canMinDownPay);

    useOnQuestionMount(loanLead, questionId, updateAndStoreLead);

    useEffect(() => {
        function onFieldStateChange() {
            const canMinDownPay = fieldState;

            const lead:LoanLead = DeepCopy.copy(loanLead)

            lead.newLoan.canMinDownPay = canMinDownPay;      
            
            updateAndStoreLead(lead);
        }
        onFieldStateChange();
    },[fieldState]);

    return (
        <>
            <div className="question-header-container">
                <h3 className="question-header-small">Can You Make A 3.5% Down Payment?</h3>
                <h3 className="question-header text-center">Can You Make A Minimum <br /> 3.5% Down Payment?</h3>
            </div>

            <div className="form-element">
                <div className="btn-two-container">
                    <div className="radio-btn-container">
                        <input 
                            checked={fieldState === true} 
                            type="radio" 
                            id="minDownYes" 
                            name="minDownPay" 
                            value="true" 
                            onChange={handleBoolChange} 
                        />
                        <label className="btn-yes btn-question" htmlFor="minDownYes" aria-label="yes"></label>
                    </div>
                    <div className="radio-btn-container">
                        <input 
                            checked={fieldState === false} 
                            type="radio" 
                            id="minDownNo" 
                            name="minDownPay" 
                            value="false" 
                            onChange={handleBoolChange} 
                        />
                        <label className="btn-no btn-question" htmlFor="minDownNo" aria-label="no"></label>
                    </div>
                </div>
            </div>
            <NavigationButtons 
                path={nextQuestion}                    
                nextPath={nextQuestion}
                prevPath={previousQuestion} 
                loanLead={loanLead}               
            />
        </>
    )
}

export default MinDownPayment