import { LoanTypeEnum } from "../../enums/LoanTypeEnum";

export type newLoanType = {    
    /**
     * If the lead can make a minimum down payment
     */
    canMinDownPay:boolean

    /**
     * If the lead can verify their income
     * TODO move to another section
     */
    verifyIncome:boolean

    /**
     * If the lead have found a home or not
     */
    foundHome:boolean
    
    /**
     * If the user wants to be match with an agent
     * Calculate this look at services loanLead
     * Only appears if the user selected no for if found home
     * Bruce, Paul, and John requested this to default to true on March 9 2022
     */
    WantAgentMatch:boolean
}

/**
 * The reason this is a class instead of a object is because we 
 * need to calculate loan values, etc before the object is created
 */
export const defaultNewLoanField:newLoanType = {       
    canMinDownPay: true,
    
    verifyIncome: true,
    
    foundHome: false,
    
    WantAgentMatch: true, 

}
