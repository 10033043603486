import React, { useEffect, useRef, useState } from 'react';
import { StringHelper } from '../../../helpers/StringHelper';
import { GeoapifyAutocomplete } from '../../../models/responses/GeoapifyResponse';
import { propertyInput } from '../../../pages/questions/bothPurchaseRefi/PropertyLoaction';
import './Typeahead.css';

type typeaheadProps = {
    data:GeoapifyAutocomplete[]
    minimumLength:number
    debounce:number
    placeholder:string    
    onChangeFunc:any
    resetData:() => void
    onSelect:any
    selectedInput:propertyInput
    onInputClear:() => void
    isLoading:boolean
}

type typeaheadState = {
    cursor:number
    showOptions: boolean
    firstExecution:boolean
    selectedInput:propertyInput | null
    showSelectedInput:boolean    
    
}

/**
 * A custom typeahead made for the property location question
 * Some functionality can be stripped out so this component can be used elsewhere
 * @param props 
 * @returns 
 */
const Typeahead = (props:typeaheadProps) => {

    const {
            data, 
            minimumLength, 
            debounce, 
            placeholder,         
            onChangeFunc, 
            resetData, 
            onSelect, 
            selectedInput,
            onInputClear,
            isLoading
        } = props;
    
    const defaultState:typeaheadState = {
        cursor: -1,
        showOptions:false,
        firstExecution:false,
        selectedInput: StringHelper.isNullOrWhiteSpace(selectedInput.city) ? null : {...selectedInput},
        showSelectedInput:false
    }

    /**
     * Reference to the typeahead options select/dropdown
     */
    const typeaheadOptionsRef = useRef<HTMLDivElement>(null);

    /**
     * Reference to the typeahead input
     */
    const typeaheadInputRef = useRef<HTMLInputElement>(null);

    const [state, setState] = useState(defaultState);

    useEffect(() => {
        // when the component mounts we are adding these event listeners
        window.addEventListener("mousedown", handleOutOptionsSideClick);
        window.addEventListener("mousedown", handleOutsideSelectedItemClick);
        
        return () => {
            //When the component unmounts we remove them so we don't carry them over to the next component
            window.removeEventListener("mousedown", handleOutOptionsSideClick);
            window.removeEventListener("mousedown", handleOutsideSelectedItemClick);
        }
    }, []);

    /**
     * updates state's cursor which keeps track of what we are selecting in the results
     * @param index 
     */
    const updateCursor = (index:number) => {
        setState(prevState => ({
            ...prevState,
            cursor:index
        }));
    }

    /**
     * perform a different actions depending on the key pressed while input is focused
     * @param e 
     */
    const onKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
        const {cursor} = state;

        let position = cursor;

        // updates the cursor position and show options if it is not already showing
        if (e.key === "ArrowDown") {            
            position = cursor === data.length - 1 ? data.length - 1 : cursor + 1;
            if(!state.showOptions && cursor === -1)
                showOptions();

            updateCursor(position);
        }
        
        if(e.key === "ArrowUp") {
            position = cursor === 0 ? 0 : cursor - 1;
            updateCursor(position);
        }
        
        // When set the cursor to -1 because we don't want to have anything selected
        if(e.key === "Escape") {
            hideOptions();
            updateCursor(-1);
        }

        if(e.key === "Enter") {
            if(position > -1 && position <= data.length - 1) {
                handleSelect(data[position])
            }
        }
    }

    /**
     * Hide the options menu
     */
    const hideOptions = () => {
        setState(prevState => ({
            ...prevState,
            showOptions:false
        }));
    }

    /**
     * Show the options menu
     */
    const showOptions = () => {
        setState(prevState => ({
            ...prevState,
            showOptions:true
        }));
    }

    /**
     * When options is showing and a user clicks outside the options div hide the options
     * @param e 
     */
    const handleOutOptionsSideClick = (e:any) => {
        if(
            typeaheadOptionsRef.current 
            && !typeaheadOptionsRef.current.contains(e.target)
        ) {
            hideOptions();
        }
    }

    /**
     * handles showing the selected input when focused on the input and then clicking
     * outside the input
     * @param e 
     * @returns 
     */
    const handleOutsideSelectedItemClick = (e:any) => {   

        if(typeaheadInputRef.current
            && typeaheadInputRef.current.contains(e.target)
            ) {                                 
            return
        }        

        setState(prevState => ({
            ...prevState,
            showSelectedInput:true
        }));
        
    }

    /**
     * delays when the function is executed
     * the reason this function is labeled as function is to avoid
     * issue with the this keyword
     * @param func 
     * @param timeout 
     * @returns 
     */
    function debounceFunc(func:any, timeout = 500){        
        let timer:any;
        
        return (...args:any) => {
            
            clearTimeout(timer);
            
            //@ts-ignore
          timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
      }

    /**
     * Handles input change 
     * @param e 
     * @returns 
     */
    const handleChange = (e:React.FormEvent<HTMLInputElement>) => {
        const inputValue = e.currentTarget.value;
        
        if(inputValue.length >= minimumLength) {            
            
           debounceFunc(() => onChangeFunc(inputValue, debounce))();              
           return;           
        }

        resetData()
    }

    /**
     * When a item is selected update state and hide options
     * @param data 
     */
    const handleSelect = (data:GeoapifyAutocomplete) => {
        hideOptions();
        
        setState(prevState => ({
            ...prevState,
            selectedInput:{
                city: data.city,
                state: data.stateCode,
                zip: data.postCode
            },
            showSelectedInput:true
        }));
        resetData();
        onSelect(data);
    }

    /**
     * Handles when the displaying selected item is clicked
     */
    const onSelectedItemClick = () => {
        setState((prevState) => ({
            ...prevState,
            showSelectedInput:false
        }));
        resetData();
    }

    /**
     * When input is in focus we need to show options
     * @returns 
     */
    const handleInputFocus = () => {
        if(state.showOptions)
            return;

        setState(prevState => ({
            ...prevState,
            showOptions:true
        }))
    }

    /**
     * Removes the current selected item
     * @param e 
     */
    const clearSelectedItem = (e:any) => {
        e.preventDefault();

        setState(prevState => ({
            ...prevState,
            showOptions:false,
            selectedInput:null
        }));
        
        onInputClear();

        resetData();
    }


    return (
        <div className="typeahead">
            {
                state.selectedInput && state.showSelectedInput ? 
                (
                    <div className="input-border zip-input typeahead-selected">
                        <div onClick={onSelectedItemClick} className='typeahead-selected-item'>
                            <span>
                                {`${StringHelper.capitalizeString(state.selectedInput.city)}, ${state.selectedInput.state.toUpperCase()}`}
                            </span>
                        </div>
                        <div 
                            onClick={clearSelectedItem}
                            className="typeahead-clear"
                        >
                            ✕
                        </div>
                    </div>
                ) 
                : 
                (
                    <>
                    <input 
                        autoFocus
                        onFocus={handleInputFocus}
                        ref={typeaheadInputRef}
                        className='input-border zip-input' 
                        placeholder={placeholder} 
                        type="text" 
                        onClick={showOptions}
                        onKeyDown={onKeyPress}
                        onChange={handleChange}
                    />  
                    {
                        isLoading && (
                            <div className="typeahead-loader"></div>
                        )
                    }
                    </>

                )
            }
          
            {state.showOptions && (
                <div ref={typeaheadOptionsRef} className="typeahead-options">
                    {
                        props.data.length > 0 ? 
                        (
                            <ul className="typeahead-results">
                            {
                                data.map((data, index) => (
                                    <li 
                                        key={data.id} 
                                        className={`typeahead-results-item ${state.cursor === index ? "result-selected" : ""}`}
                                        onClick={() => handleSelect(data)}
                                    >
                                        <img src='/icons/pin-map.svg' />
                                        <span>
                                            &nbsp;&nbsp;{data.city}, {data.stateCode} {data.country}
                                        </span>
                                    </li>
                            ))}
                            </ul>
                        ) 
                        : 
                        (
                            <div  className="typeahead-results-not-found">
                                {" No Results "}
                            </div>
                        )
                    }
                </div>
            )}
        </div>
        

    )
}

export default Typeahead;