export class BoolHelper {
    static convertStringToBool = (str:string) => {
        str = str.toLowerCase();
        if(str === "true")
            return true;
        if(str === "false")
            return false;

        throw new Error("Invalid Input");
    }
}