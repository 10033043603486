import React from 'react';
import '../../styles/footers/Footer.css';

const Footer = () => {
    const equalHousingSrc = `${process.env.PUBLIC_URL}/images/global/equalhousing.png`;

    const comodoSecureSrc = `${process.env.PUBLIC_URL}/images/global/comodo_secure.png`;
    //TODO get this from cid
    const mobileLogoSrc = `${process.env.PUBLIC_URL}/images/fha/fha-logo.png`;

    /**
     * May not use this it is from blazor
     */
    const navigateToNewWindow = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        
        window.open(e.currentTarget.href,'','width=400,height=600,scrollbars=yes');
    }

    return (        
        <section className="footer-section">
            <footer className="footer">
                <ul className="footer-links center-content">
                    <li>
                        <a onClick={navigateToNewWindow} href="/privacy-policy">Privacy Policy</a>  |
                    </li>
                    <li>
                        <a onClick={navigateToNewWindow} href="/terms-service">*Terms of Service</a> |
                    </li>
                    <li>
                        <a onClick={navigateToNewWindow} href="/disclaimer">Disclaimer </a> |
                    </li>
                    <li>
                        <a onClick={navigateToNewWindow} href="/contact">Contact</a>
                    </li>
                </ul>
                <span className="copyright-text">
                    Copyright &copy; 1997-{new Date().getFullYear()}
                </span>

                <div className="footer-logo">
                    <div className="footer-logo-img">
                        <img src={equalHousingSrc} alt="Equal housing" width="65" height="70"/>
                    </div>
                    <div className="footer-logo-img">
                        <img src={comodoSecureSrc} alt="Comodo Secure" width="113" height="59"/>
                    </div>
                    <div className="footer-logo-container">
                        <img 
                            className="footer-logo-img-small"                              
                            width="360" 
                            height="120" 
                            src={mobileLogoSrc}
                            alt="FHA.com: Home Purchase and Refinance Loans" />
                    </div>
                </div>

                <p className="text-disclaimer">
                FHALoan.com is not a government agency. We do not offer or have any affiliation with loan modification,
                foreclosure prevention, payday loan, or short term loan services. Neither FHALoan.com nor its advertisers
                charge a fee or require anything other than a submission of qualifying information for comparison shopping ads.
                We do not ask users to surrender or transfer title. We do not ask users to bypass their lender. We encourage users
                to contact their lawyers, credit counselors, lenders, and housing counselors
                </p>
            </footer>
        </section>
        
    )
}

export default Footer