import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import '../../styles/layouts/Layout.css';

const ThankYouLayout = () => {
    return (
        <React.Fragment>
            <Header/>
            <div className="content-section">                                    
                <Outlet />                                                
            </div>
            <Footer/>
        </React.Fragment>
    )
}

export default ThankYouLayout;